<template>
	<VList
		ref="profileSwitcher"
		class="px-4 mb-md-2"
		density="compact"
		nav
	>
		<VListItem
			class="pl-1"
			:title="activeDisplayProfile?.title"
			:subtitle="activeDisplayProfile?.subtitle"
			:link="hasMultipleProfiles"
			data-testid="navigationProfileSwitcher"
		>
			<template #prepend>
				<CommonAvatar
					:src="activeDisplayProfile?.avatar"
					size="40"
				/>
			</template>
			<template
				v-if="hasMultipleProfiles && isExpanded"
				#append
			>
				<VIcon
					icon="swap_horiz"
					size="16"
				/>
			</template>
			<VMenu
				v-if="hasMultipleProfiles"
				v-model="isMenuActive"
				theme="INFLCRLight"
				activator="parent"
				location="bottom"
				:close-on-content-click="false"
			>
				<VCard
					color="background"
					width="275"
					border
				>
					<VCardTitle class="text-h4 text-high-emphasis">
						{{ t('profile', 0) }}
					</VCardTitle>
					<VCardText class="pa-3">
						<BaseField
							v-slot="{id}"
							:label="t('search')"
							hide-label
							class="mb-4"
						>
							<VTextField
								:id="id"
								ref="fieldProfilesSearch"
								v-model="profilesSearch"
								:placeholder="t('search')"
								hide-details
								data-testid="navigationProfileSwitcherSearch"
								autocomplete="off"
							/>
						</BaseField>
						<template
							v-for="group in profilesGrouped"
							:key="group.role"
						>
							<div class="text-caption text-medium-emphasis px-4">
								{{ group.displayName }}
							</div>
							<VList
								bg-color="background"
								nav
							>
								<VListItem
									v-for="profile, index in group.profiles"
									:key="profile.uuid"
									class="px-3 text-decoration-none"
									:disabled="profile.disabled"
									:data-testid="getTestId(group.role.toString(), index)"
									:link="userStore.profile?.uuid !== profile.uuid"
									:href="profile.href ?? undefined"
									@click="onProfileClick(profile)"
								>
									<template #prepend>
										<CommonAvatar
											:src="profile.avatar"
											class="mr-3"
											size="24"
										/>
									</template>
									<template #title>
										<span class="text-body-2 font-weight-semibold">
											{{ profile.title }}
										</span>
									</template>
									<template
										v-if="userStore.profile?.uuid === profile.uuid"
										#append
									>
										<VIcon
											color="success"
											icon="check_circle"
										/>
									</template>
									<VTooltip
										v-if="!mobile"
										:data-testid="getTooltipTestId(group.role.toString(), index)"
										activator="parent"
										location="right"
									>
										{{ profile.title }}
									</VTooltip>
								</VListItem>
							</VList>
						</template>
					</VCardText>
				</VCard>
			</VMenu>
			<VTooltip
				v-if="!isExpanded"
				activator="parent"
				location="right"
				open-delay="500"
			>
				{{ activeDisplayProfile?.title }}
			</VTooltip>
		</VListItem>
	</VList>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useUserProfiles, type IDisplayProfile } from '@/composables';
import CommonAvatar from '@/components/CommonAvatar.vue';
import BaseField from '@/components/BaseField.vue';
import { useUserStore } from '@/stores/userStore';
import { VMenu } from 'vuetify/components/VMenu';
import { useRouter } from 'vue-router';
import { $ResetPinia } from '@/plugins/pinia';

defineProps<{
	isExpanded: boolean;
}>();

const { mobile } = useDisplay();
const { t } = useI18n();
const router = useRouter();

const {
	profilesGrouped,
	profilesSearch,
	activeDisplayProfile,
	hasMultipleProfiles,
} = useUserProfiles();

const isMenuActive = ref(false);
const userStore = useUserStore();

function onProfileClick(profile: IDisplayProfile) {
	if (profile.href) return;
	if (userStore.profile?.uuid !== profile.uuid) switchProfile(profile.uuid);
}

async function switchProfile(profileId: string) {
	isMenuActive.value = false;
	profilesSearch.value = '';
	router.replace({ query: {} });
	userStore.setProfile(profileId);
	await onProfileSwitch();
	$ResetPinia().profile();
}

async function onProfileSwitch() {
	const { requiredAccess, profileSwitchRedirect } = router.currentRoute.value.meta;
	if (requiredAccess && !userStore.profileAccess[requiredAccess]) await router.push({ name: userStore.profileDefaultRoute });
	else if (profileSwitchRedirect) await router.push({ name: profileSwitchRedirect });
}

function getTestId(role: string, index: number) {
	return `navigationProfileSwitcherItem_Role${role}_Item${index + 1}`;
}

function getTooltipTestId(role: string, index: number) {
	return `navigationProfileSwitcherItemTooltip_Role${role}_Item${index + 1}`;
}
</script>
