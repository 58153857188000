import { defineStore } from 'pinia';
import { reactive, toRefs, computed } from 'vue';
import { cloneDeep } from 'lodash-es';
import { type ExchangePaymentMethodStripe, VERIFIED_STATUSES } from '@/types/paymentMethods';

interface UseExchangePaymentMethodsStripeStoreState {
	paymentMethods: ExchangePaymentMethodStripe[] | undefined;
}

export const useExchangePaymentMethodsStripeStore = defineStore('exchange-payment-methods-stripe', () => {
	const DEFAULT_STATE = {
		paymentMethods: undefined,
	};

	const state = reactive<UseExchangePaymentMethodsStripeStoreState>(cloneDeep(DEFAULT_STATE));

	const setPaymentMethods = (payload: ExchangePaymentMethodStripe[] | undefined) => {
		state.paymentMethods = payload ? [...payload] : undefined;
	};

	const updateDefaultPaymentMethod = (paymentMethod: ExchangePaymentMethodStripe) => {
		if (!state.paymentMethods) return;

		const currentDefaultMethod = state.paymentMethods.find((method) => method.isDefault);
		if (currentDefaultMethod) currentDefaultMethod.isDefault = false;

		paymentMethod.isDefault = true;
	};

	const defaultPaymentMethod = computed(() => {
		if (!state.paymentMethods) return;
		return state.paymentMethods.find((method) => method.isDefault);
	});

	const hasDefaultPaymentMethod = computed(() => {
		return !!defaultPaymentMethod.value;
	});

	const hasVerifiedPaymentMethod = computed(() => {
		if (!state.paymentMethods) return false;
		return !!state.paymentMethods.find((method) => method.verifiedStatus === VERIFIED_STATUSES.VERIFIED);
	});

	const updatePaymentMethodLabel = (uuid: string, label: string) => {
		if (!state.paymentMethods) return;
		state.paymentMethods = state.paymentMethods.map((method) => {
			if (method.id === uuid) method.label = label;
			return method;
		});
	};

	const removePaymentMethod = (uuid: string) => {
		if (!state.paymentMethods) return;
		state.paymentMethods = state.paymentMethods.filter((method) => method.id !== uuid);
	};

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	return {
		...toRefs(state),
		setPaymentMethods,
		updateDefaultPaymentMethod,
		defaultPaymentMethod,
		hasDefaultPaymentMethod,
		hasVerifiedPaymentMethod,
		updatePaymentMethodLabel,
		removePaymentMethod,
		$reset,
	};
});
