import type {
	ListResponse,
	ListResponseCursor,
	SortDirection,
} from '@/api/utilities/provider';
import { paymentGateways } from '@/utils/paymentMethods';

import type {
	ApiMediaAttachment,
	Media,
} from './media';
import type { PaymentGateway } from './paymentMethods';
import type {
	Athlete,
	BaseUser,
} from './users';

const TRANSACTION_TYPES = {
	PUBLIC_APPEARANCE: 1,
	SOCIAL_MEDIA: 2,
	CAMPS_AND_LESSONS: 3,
	AUTOGRAPHS: 4,
	BUSINESS_OWNERSHIP_CREATION: 5,
	OUTSIDE_EMPLOYMENT: 6,
	REPRESENTATION_DISCLOSURE: 7,
	OTHER: 8,
	ROYALTY: 9,
	NOT_SURE: 10,
} as const;

type TransactionType = typeof TRANSACTION_TYPES[keyof typeof TRANSACTION_TYPES];

const EXCHANGE_STATUSES = {
	PROPOSED: 0,
	DECLINED: 1,
	APPROVED: 2,
	EXPIRED: 3,
	PAYMENT_PROCESSING: 30,
	PAYMENT_FAILED: 40,
	PAYMENT_COMPLETED: 50,
	PAYMENT_ON_HOLD: 60,
	TRANSFER_PROCESSING: 70,
	TRANSFER_FAILED: 80,
	TRANSFER_COMPLETE: 90,
	PAYOUT_PENDING: 100,
	PAYOUT_PROCESSING: 110,
	PAYOUT_FAILED: 120,
	PAYOUT_COMPLETED: 130,
	PAID_OUTSIDE_INFLCR: 140,
} as const;

type ExchangeStatus = typeof EXCHANGE_STATUSES[keyof typeof EXCHANGE_STATUSES];

const LEADERBOARD_STATUSES = {
	NOT_READY_FOR_REVIEW: 0,
	READY_FOR_REVIEW: 1,
	APPROVED: 2,
	REJECTED: 3,
} as const;

type LeaderboardStatus = typeof LEADERBOARD_STATUSES[keyof typeof LEADERBOARD_STATUSES];

const TRANSACTION_UNIT_TYPES = {
	US_DOLLARS: 0,
	POINTS: 1,
} as const;

type TransactionUnitType = typeof TRANSACTION_UNIT_TYPES[keyof typeof TRANSACTION_UNIT_TYPES];

const TRANSACTION_ACTION_KEYS = [
	'addToList',
	'archive',
	'duplicate',
	'edit',
	'setToPaidOutsideInflcr',
	'setToAccepted',
	'sendMessage',
	'sendPayment',
	'agentAccept',
	'agentDecline',
	'leaderboardReview',
	'leaderboardApprove',
	'leaderboardReject',
] as const;

type TransactionActionKey = typeof TRANSACTION_ACTION_KEYS[number];

const TRANSACTION_TASK_STATUSES = {
	INCOMPLETE: 0,
	COMPLETE: 255,
} as const;

type TransactionTaskStatus = typeof TRANSACTION_TASK_STATUSES[keyof typeof TRANSACTION_TASK_STATUSES];

const TRANSACTION_PAYMENT_FEES = {
	ACH_PERCENTAGE_FEE: 0,
	CARD_FLAT_FEE: 1,
	CARD_PERCENTAGE_FEE: 2,
	PAYOUT_FLAT_FEE: 3,
	PAYOUT_INSTANT_PERCENTAGE_FEE: 4,
	PAYOUT_PERCENTAGE_FEE: 5,
} as const;

type TransactionPaymentFee = typeof TRANSACTION_PAYMENT_FEES[keyof typeof TRANSACTION_PAYMENT_FEES];

const PAYABLE_STATUSES = {
	CAN_PAY: 0,
	UNRESOLVED_TASKS: 1,
	AWAITING_SCHOOL: 2,
	AWAITING_ATHLETE: 3,
	ATHLETE_DECLINED: 4,
	ALREADY_PAID: 5,
	PAID_OUTSIDE_INFLCR: 6,
	NOT_PAYABLE: 7,
	EXPIRED: 8,
} as const;

type PayableStatus = typeof PAYABLE_STATUSES[keyof typeof PAYABLE_STATUSES];

const TRANSACTION_STATUSES = {
	PROPOSED: -1,
	SUBMITTED: 0,
	UPDATED: 1,
	FLAGGED: 2,
	APPROVED: 3,
	IN_REVIEW: 4,
	REVIEWED: 5,
} as const;

type TransactionStatus = typeof TRANSACTION_STATUSES[keyof typeof TRANSACTION_STATUSES];

const TRANSACTION_REPORTING_METHODS = {
	SELF: 0,
	EXTERNAL: 1,
	THIRD_PARTY: 2,
	MPORT: 3,
	LOCAL_EXCHANGE: 4,
	GLOBAL_EXCHANGE: 5,
} as const;

type TransactionReportingMethod = typeof TRANSACTION_REPORTING_METHODS[keyof typeof TRANSACTION_REPORTING_METHODS];

const TRANSACTION_PAYMENT_TYPES = {
	CASH: 0,
	'PRODUCT/EQUITY': 1,
	POINTS: 2,
} as const;

type TransactionPaymentType = typeof TRANSACTION_PAYMENT_TYPES[keyof typeof TRANSACTION_PAYMENT_TYPES];

interface TasksOverview {
	total: number;
	completed: number;
	percentage: number | null;
}

interface ApiBaseTransaction {
	uuid: string;
	name: string;
	school: {
		uuid: string;
		name: string;
	};
	cost: number; // represents base points when brand uses points
	created_at: string;
	updated_at: string;
	is_approval_required_for_payment: boolean | null;
	athlete: Pick<Athlete, 'uuid' | 'first_name' | 'last_name'> | null;
	exchange_status: ExchangeStatus | null;
	status: TransactionStatus;
	payment_gateway: PaymentGateway | null;
	unit_type: TransactionUnitType;
	leaderboard: {
		status: LeaderboardStatus;
		multiplier: number;
	} | null;
}

type ApiExportTransactionPayload = Pick<
	GetTransactionsRequestParams,
	| 'created_at[after]'
	| 'created_at[before]'
	| 'exchange_status'
	| 'organizations'
	| 'payable'
	| 'transaction_name'
>;

interface ApiTransactionListTransaction extends ApiBaseTransaction {
	tasks_overview: TasksOverview;
}

interface ApiTransactionTask {
	uuid: string;
	name: string;
	completed_by: BaseUser | null;
	description?: string;
	attachments?: Media[];
	completed_at: string | null;
	created_at: string | null;
	due_date?: string;
	status: TransactionTaskStatus;
	link: string | null;
}

interface TransactionTask {
	name: string;
	description: string;
	due_date?: string;
	attachments?: ApiMediaAttachment[];
}

interface ApiTransactionFee {
	fee_type: TransactionPaymentFee;
	amount: number;
}

interface ApiVendor {
	name: string;
	phone?: string | null;
	point_of_contact: string;
	email: string;
}

interface Vendor {
	name: string;
	phone?: string | null;
	pointOfContact: string;
	email: string;
}

interface ApiAgent {
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
}
class Agent {
	firstName: string;
	lastName: string;
	fullName: string;
	email: string;
	phone: string;
	constructor(agent: ApiAgent) {
		this.firstName = agent.first_name;
		this.lastName = agent.last_name;
		this.fullName = `${agent.first_name} ${agent.last_name}`;
		this.email = agent.email;
		this.phone = agent.phone;
	}
}

interface ApiTransaction extends ApiBaseTransaction {
	attachments: Media[];
	id: number;
	date: string;
	expiration_date?: string;
	multiple_dates: 0 | 1;
	multiple_dates_text: string | null;
	notes: string;
	payment_type: TransactionPaymentType;
	transaction_type: TransactionType;
	tasks: ApiTransactionTask[];
	vendor: ApiVendor;
	agent: ApiAgent | null;
	transaction_fees: ApiTransactionFee[];
	exchange_brand_user: Pick<BaseUser, 'uuid'>;
}

interface ApiCreateTransactionRequestModel {
	name: string;
	transaction_type: TransactionType;
	payment_type: TransactionPaymentType;
	cost: number;
	date: string;
	expiration_date?: string;
	multiple_dates?: boolean;
	multiple_dates_text?: string;
	notes: string;
	unit_type: TransactionUnitType;
	attachments?: ApiMediaAttachment[];
	exchange_bulletin_uuid?: string;
	vendor: ApiVendor;
	tasks?: TransactionTask[];
	message?: string;
	has_athlete_accepted_transaction?: boolean;
	athletes: string[];
}

interface ApiUpdateTransactionRequestModel {
	name?: string;
	transaction_type?: TransactionType;
	payment_type?: TransactionPaymentType;
	cost?: number;
	date?: string;
	expiration_date?: string;
	notes?: string;
	vendor?: ApiVendor;
	exchange_status?: ExchangeStatus;
	leaderboard_status?: Extract<LeaderboardStatus, typeof LEADERBOARD_STATUSES.APPROVED | typeof LEADERBOARD_STATUSES.REJECTED>;
	multiple_dates?: boolean;
	multiple_dates_text?: string;
}

class BaseTransaction {
	uuid: string;
	name: string;
	school: {
		uuid: string;
		name: string;
	} | null;

	cost: number;
	createdAt: string;
	updatedAt: string;
	isApprovalRequiredForPayment: boolean | null;
	athlete: { uuid: string; fullName: string } | null;
	exchangeStatus: ExchangeStatus | null;
	status: TransactionStatus | null;
	fees?: TransactionCostBreakdown | undefined;
	paymentGateway: typeof paymentGateways[number] | null;
	unitType: TransactionUnitType;
	leaderboardStatus?: LeaderboardStatus;
	leaderboardMultiplier?: number;
	usesPoints: boolean;
	totalPoints?: number;

	constructor(transaction: ApiTransactionListTransaction | ApiRecentTransaction | ApiTransaction) {
		this.name = transaction.name;
		this.uuid = transaction.uuid;
		this.cost = transaction.cost;
		this.createdAt = transaction.created_at;
		this.updatedAt = transaction.updated_at;
		this.exchangeStatus = transaction.exchange_status;

		this.school = 'school' in transaction ? transaction.school : null;
		this.status = 'status' in transaction ? transaction.status : null;
		this.isApprovalRequiredForPayment = 'is_approval_required_for_payment' in transaction
			? transaction.is_approval_required_for_payment
			: null;
		this.unitType = transaction.unit_type;

		if ('athlete' in transaction) {
			this.athlete = transaction.athlete
				? {
						uuid: transaction.athlete.uuid,
						fullName: `${transaction.athlete.first_name} ${transaction.athlete.last_name}`,
					}
				: null;
		} else {
			this.athlete = null;
		}

		this.paymentGateway = paymentGateways.find((gateway) => gateway.id === transaction.payment_gateway) || null;

		if ('leaderboard' in transaction && transaction.leaderboard !== null) {
			this.leaderboardStatus = transaction.leaderboard.status;
			this.leaderboardMultiplier = transaction.leaderboard.multiplier;
		}

		this.usesPoints = this.unitType === TRANSACTION_UNIT_TYPES.POINTS;

		if (this.usesPoints) {
			this.totalPoints = this.cost * (this.leaderboardMultiplier || 1);
		}
	}
}

class TransactionListTransaction extends BaseTransaction {
	tasksOverview: TasksOverview;
	constructor(transaction: ApiTransactionListTransaction) {
		super(transaction);
		this.tasksOverview = transaction.tasks_overview;
	}
}

class Transaction extends BaseTransaction {
	id: number;
	attachments: Media[];
	date: string;
	expirationDate?: string | null;
	multipleDates: boolean;
	multipleDatesText: string | null;
	notes: string | null;
	paymentType: TransactionPaymentType;
	transactionTypeId: TransactionType;
	tasks: ApiTransactionTask[];
	vendor: Vendor;
	agent: Agent | null;
	transactionFees: ApiTransactionFee[];
	exchangeBrandUser: Pick<BaseUser, 'uuid'>;

	constructor(transaction: ApiTransaction) {
		super(transaction);
		this.id = transaction.id;
		this.attachments = transaction.attachments;
		this.date = transaction.date;
		this.expirationDate = transaction.expiration_date;
		this.multipleDates = !!transaction.multiple_dates;
		this.multipleDatesText = transaction.multiple_dates_text;
		this.notes = transaction.notes;
		this.paymentType = transaction.payment_type;
		this.transactionTypeId = transaction.transaction_type;
		this.tasks = transaction.tasks;
		this.vendor = {
			email: transaction.vendor.email,
			name: transaction.vendor.name,
			phone: transaction.vendor.phone,
			pointOfContact: transaction.vendor.point_of_contact,
		};
		this.agent = transaction.agent && new Agent(transaction.agent);
		this.exchangeBrandUser = transaction.exchange_brand_user;

		this.transactionFees = transaction.transaction_fees;
	}
}

type GetTransactionsResponse = ListResponse<ApiTransactionListTransaction>;

type TransactionsSortValue = 'transaction_name' | 'applicant' | 'value' | 'task_completion' | 'exchange_status' | 'updated_at';

type TransactionsModelType = 'exchange_bulletin';

interface GetTransactionsRequestParams {
	sort?: [TransactionsSortValue, SortDirection][];
	page?: number;
	per_page?: number;
	organizations?: string[];
	search?: string;
	athlete_name?: string;
	transaction_name?: string;
	exchange_status?: ExchangeStatus[];
	leaderboard_status?: LeaderboardStatus[];
	status?: TransactionStatus[];
	payable?: boolean;
	'created_at[after]'?: string;
	'created_at[before]'?: string;
	model_type?: TransactionsModelType;
	model_uuid?: string;
}
type TransactionsFilters = Omit<GetTransactionsRequestParams, 'search' | 'page' | 'sort' | 'per_page'>;

interface TransactionsOverview {
	averageValue: number;
	numberPaid: number;
	totalValue: number;
}

interface GetTransactionsOverviewResponse {
	data: TransactionsOverview;
}

interface GetTransactionResponse {
	data: ApiTransaction;
}

interface SendTransactionPaymentRequestModel {
	gateway: PaymentGateway;
	identifier: string;
}

interface GetTransactionCostBreakdownRequestParams {
	transaction_uuids: string[];
	amount?: number;
}

interface TransactionCostBreakdown {
	transaction_uuid: string;
	cost: number;
	payment_types: {
		card: {
			cost: number;
			payment_type: string;
			payout_transfer_fee: number;
			payment_processing_fee: number;
			total: number;
		};
		ach: {
			cost: number;
			payment_type: string;
			payout_transfer_fee: number;
			payment_processing_fee: number;
			total: number;
		};
	};
}

interface GetTransactionCostBreakdownResponse {
	data: TransactionCostBreakdown[];
}

type ApiUpdateTransactionTaskRequestModel = {
	status: TransactionTaskStatus;
} | { link: string };

interface ApiUpdateTransactionTaskResponse {
	data: ApiTransactionTask;
}

interface GetRecentTransactionsParams {
	per_page?: number;
	cursor?: string;
}

type ApiGetRecentTransactionsResponse = ListResponseCursor<ApiRecentTransaction>;

type ApiRecentTransaction = Pick<
	ApiBaseTransaction,
	| 'uuid'
	| 'name'
	| 'cost'
	| 'created_at'
	| 'updated_at'
	| 'exchange_status'
	| 'payment_gateway'
	| 'unit_type'
>;

interface ApiTransactionImport {
	athlete_first_name: string;
	athlete_last_name: string;
	athlete_email: string;
	transaction_value: number;
	transaction_name: string;
	transaction_identifier: string;
	transaction_type: TransactionType;
	transaction_notes?: string;
	transaction_date: string;
	vendor_name: string;
	vendor_email: string;
	vendor_point_of_contact: string;
	multiple_date?: boolean;
	multiple_date_text?: string;
	used_service_provider?: boolean;
	service_provider_text?: string;
}

export {
	Agent,
	type ApiCreateTransactionRequestModel,
	type ApiGetRecentTransactionsResponse,
	type ApiExportTransactionPayload,
	type ApiTransaction,
	type ApiTransactionFee,
	type ApiTransactionImport,
	type ApiTransactionListTransaction,
	type ApiTransactionTask,
	type ApiUpdateTransactionRequestModel,
	type ApiUpdateTransactionTaskRequestModel,
	type ApiUpdateTransactionTaskResponse,
	BaseTransaction,
	EXCHANGE_STATUSES,
	type ExchangeStatus,
	type GetRecentTransactionsParams,
	type GetTransactionCostBreakdownRequestParams,
	type GetTransactionCostBreakdownResponse,
	type GetTransactionResponse,
	type GetTransactionsOverviewResponse,
	type GetTransactionsRequestParams,
	type GetTransactionsResponse,
	LEADERBOARD_STATUSES,
	type LeaderboardStatus,
	PAYABLE_STATUSES,
	type PayableStatus,
	type SendTransactionPaymentRequestModel,
	Transaction,
	TRANSACTION_ACTION_KEYS,
	TRANSACTION_PAYMENT_FEES,
	TRANSACTION_PAYMENT_TYPES,
	TRANSACTION_REPORTING_METHODS,
	TRANSACTION_STATUSES,
	TRANSACTION_TASK_STATUSES,
	TRANSACTION_TYPES,
	TRANSACTION_UNIT_TYPES,
	type TransactionActionKey,
	type TransactionCostBreakdown,
	TransactionListTransaction,
	type TransactionPaymentFee,
	type TransactionPaymentType,
	type TransactionReportingMethod,
	type TransactionsFilters,
	type TransactionsModelType,
	type TransactionsOverview,
	type TransactionsSortValue,
	type TransactionStatus,
	type TransactionTask,
	type TransactionTaskStatus,
	type TransactionType,
	type TransactionUnitType,
};
