import type { ListResponse } from '@/api/utilities/provider';

import type { ApiMediaAttachment } from './media';
import type { TransactionUnitType } from './transactions';

const EXCHANGE_BRAND_USER_TYPES = {
	BASE: 0,
	EXCHANGE_ADMIN: 255,
} as const;

type ExchangeBrandUserType = typeof EXCHANGE_BRAND_USER_TYPES[keyof typeof EXCHANGE_BRAND_USER_TYPES];

const EXCHANGE_BRAND_ENTITY_TYPES = {
	BUSINESS: 0,
	INDIVIDUAL: 1,
} as const;

type ExchangeBrandEntityType = typeof EXCHANGE_BRAND_ENTITY_TYPES[keyof typeof EXCHANGE_BRAND_ENTITY_TYPES];

interface ExchangeMember {
	created_at: string;
	uuid: string;
	first_name: string;
	last_name: string;
	profile_image: string | null;
	email: string;
	confirmed: boolean;
	is_admin: boolean;
}

interface SingleExchangeMember {
	createdAt: string;
	uuid: string;
	firstName: string;
	lastName: string;
	fullName: string;
	identifier: string;
	profileImage: string | null;
	email: string;
	confirmed: boolean;
	isAdmin: boolean;
}

const EXCHANGE_MEMBER_ACTION_KEYS = [
	'promote',
	'resendInvite',
	'remove',
] as const;

type ExchangeMemberActionKey = typeof EXCHANGE_MEMBER_ACTION_KEYS[number];

interface ApiExchangeBrandProfile {
	active_bulletins_allowed: number | null;
	active_bulletins_count: number;
	allows_athletes_to_initiate_conversations: boolean;
	can_adjust_status_on_athletes_behalf: boolean;
	entity_type: ExchangeBrandEntityType;
	featured_bulletins_count: number;
	is_global: boolean;
	logo: string | null;
	max_athletes_per_bulk_message: number;
	max_number_of_featured_bulletins: number;
	name: string;
	payments_ready: boolean;
	updated_at: string;
	uuid: string;
	is_wallet_visible: boolean;
	leaderboard: {
		enabled: boolean;
		unit_type: TransactionUnitType;
	};
}

interface ApiExchangeBrandBillingDetails {
	address: string | null;
	city: string | null;
	country: string | null;
	description: string | null;
	ein: string | null;
	state: string | null;
	url: string | null;
	zip: string | null;
}

type ApiExchangeBrandWithBillingDetails = ApiExchangeBrandProfile & ApiExchangeBrandBillingDetails;

interface ApiExchangeBrandProfileResponse {
	data: ApiExchangeBrandWithBillingDetails;
}

type ApiUpdateExchangeBrandRequestParams = Partial<
	Pick<ApiExchangeBrandWithBillingDetails,
		| 'url'
		| 'description'
		| 'name'
		| 'ein'
		| 'address'
		| 'city'
		| 'state'
		| 'zip'
		| 'country'
		| 'allows_athletes_to_initiate_conversations'
	> & { logo: ApiMediaAttachment }
>;

type ApiGetExchangeMembersResponse = ListResponse<ExchangeMember>;

type ApiExchangeMembersInviteMembersModel = Array<{
	email: string;
	is_admin: boolean;
}>;

type ApiExchangeMemberUpdateModel = {
	is_admin: boolean;
};

class SingleExchangeMemberProfile implements SingleExchangeMember {
	createdAt: string;
	uuid: string;
	firstName: string;
	lastName: string;
	fullName: string;
	identifier: string;
	profileImage: string | null;
	email: string;
	confirmed: boolean;
	isAdmin: boolean;

	constructor(data: ExchangeMember) {
		this.createdAt = data.created_at;
		this.uuid = data.uuid;
		this.firstName = data.first_name;
		this.lastName = data.last_name;
		this.fullName = `${data.first_name} ${data.last_name}`;
		this.identifier = data.first_name || data.last_name ? `${data.first_name} ${data.last_name}` : data.email;
		this.profileImage = data.profile_image;
		this.email = data.email;
		this.confirmed = data.confirmed;
		this.isAdmin = data.is_admin;
	}
}

export {
	type ApiExchangeBrandBillingDetails,
	type ApiExchangeBrandProfile,
	type ApiExchangeBrandProfileResponse,
	type ApiExchangeBrandWithBillingDetails,
	type ApiExchangeMembersInviteMembersModel,
	type ApiExchangeMemberUpdateModel,
	type ApiGetExchangeMembersResponse,
	type ApiUpdateExchangeBrandRequestParams,
	EXCHANGE_BRAND_ENTITY_TYPES,
	EXCHANGE_BRAND_USER_TYPES,
	EXCHANGE_MEMBER_ACTION_KEYS,
	type ExchangeBrandEntityType,
	type ExchangeBrandUserType,
	type ExchangeMember,
	type ExchangeMemberActionKey,
	type SingleExchangeMember,
	SingleExchangeMemberProfile,
};
