import type { ListResponse, SortDirection } from '@/api/utilities/provider';
import type { Gallery } from './galleries';
import type { User } from './users';

const MEDIA_FILE_TYPES = ['pdf', 'image', 'video', 'audio', 'other'] as const;

type MediaFileType = typeof MEDIA_FILE_TYPES[number];

type MediaConversionSize = 'original' | 'small' | 'medium' | 'large' | 'thumb';

interface MediaConversion {
	size: number | null;
	url: string | null;
}

interface Media {
	uuid: string;
	file_name: string;
	mime_type: string;
	file_size: number;
	file_type: MediaFileType;
	conversions: Partial<Record<MediaConversionSize, MediaConversion>>
		& { original: NonNullableFields<MediaConversion> };
}

interface ApiMediaAttachment {
	fileName: string;
	key: string;
}

const TAG_TYPES = {
	PROVIDER_DATA: 1,
	FILE_DATA: 2,
	FILE_NAME: 3,
	AI_FACE_MATCH: 4,
	AI_JERSEY_NUMBER: 5,
	MANUAL: 6,
	AI_LOGO_DETECTION: 7,
} as const;
type TagType = typeof TAG_TYPES[keyof typeof TAG_TYPES];

const TAG_STATUSES = {
	UNTAGGED: 0,
	TAGGED: 1,
} as const;
type TagStatus = typeof TAG_STATUSES[keyof typeof TAG_STATUSES];

interface MediaUserTag {
	uuid: string;
	first_name: string;
	last_name: string;
	jersey_number: string | null;
}

interface MediaTags {
	users: MediaUserTag[];
	total: number;
}

type MediaRedirectEventType = 'view' | 'download';

interface MediaGallery extends Media {
	tags: MediaTags;
	gallery: Pick<Gallery, 'uuid' | 'title' | 'category'>;
}

const isMediaGallery = (media: Media | MediaGallery): media is MediaGallery => {
	return 'gallery' in media;
};

interface MediaGalleryDetailed extends MediaGallery {
	keywords: string[];
	uploaded_by: Pick<User, 'uuid' | 'first_name' | 'last_name'>;
	source: string | null;
	attribution: string;
	shared_copy: string;
	activities: {
		total_likes: number;
		total_dislikes: number;
		total_views: number;
		total_shares: number;
		total_downloads: number;
	};
}

type GetMediaResponse = {
	data: MediaGalleryDetailed;
};

type GetMediaListResponse = ListResponse<MediaGallery>;

interface GetMediaUuidsResponse {
	data: {
		uuid: string;
	}[];
}

const MEDIA_ACTIVITY_TYPES = [
	'view',
	'share',
	'download',
	'share_click',
	'tagged',
	'like',
	'dislike',
] as const;
type MediaActivityType = typeof MEDIA_ACTIVITY_TYPES[number];

type MediaActivity = {
	first_name: string;
	last_name: string;
	group: string;
	created_at: string;
} & ({
	type: Exclude<MediaActivityType, 'tagged'>;
} | {
	type: 'tagged';
	tag_type: TagType;
});

type GetMediaActivityResponse = {
	data: MediaActivity[];
};

type MediaRequestSortValue = 'file_name' | 'created_at' | 'tag_per_athlete';
type MediaRequestSort = [MediaRequestSortValue, SortDirection][];

interface MediaRequestFilters {
	tagged?: TagStatus;
	tag_type_ids?: TagType[];
	content_keywords?: string[];
}

const MEDIA_MODEL_TYPES = [
	'transaction_task',
	'gallery',
	'exchange_brand',
	'opportunity',
	'team',
	'school',
] as const;
type MediaModelType = typeof MEDIA_MODEL_TYPES[number];

interface MediaRequestParams extends MediaRequestFilters {
	belongs_to: MediaModelType;
	belongs_to_id?: string;
	tagged_user_id?: string;
	per_page?: number;
	sort?: MediaRequestSort;
	all?: 0 | 1;
}

interface AttachMediaRequestModel {
	attachments: ApiMediaAttachment[];
}

interface MediaModel {
	type: MediaModelType;
	id: string;
}

interface MediaUpdateModel {
	shared_copy?: string;
	attribution?: string;
	keywords?: {
		add?: string[];
		remove?: string[];
	};
	tags?: {
		add?: string[];
		remove?: string[];
		overwrite?: boolean;
	};
}

interface MediaDownloadModel {
	conversion_size: MediaConversionSize;
	include_metadata?: boolean;
}

export {
	MEDIA_MODEL_TYPES,
	MEDIA_FILE_TYPES,
	TAG_TYPES,
	TAG_STATUSES,
	MEDIA_ACTIVITY_TYPES,
	isMediaGallery,
	type MediaFileType,
	type MediaConversionSize,
	type MediaConversion,
	type Media,
	type ApiMediaAttachment,
	type TagType,
	type TagStatus,
	type MediaUserTag,
	type MediaTags,
	type MediaRedirectEventType,
	type MediaGallery,
	type MediaGalleryDetailed,
	type GetMediaResponse,
	type GetMediaListResponse,
	type GetMediaUuidsResponse,
	type MediaActivityType,
	type MediaActivity,
	type GetMediaActivityResponse,
	type MediaRequestSortValue,
	type MediaRequestSort,
	type MediaRequestFilters,
	type MediaRequestParams,
	type MediaModelType,
	type MediaModel,
	type MediaUpdateModel,
	type MediaDownloadModel,
	type AttachMediaRequestModel,
};
