import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

interface DatadogConfig {
	clientToken: string;
	env?: string;
	service?: string;
	sessionSampleRate?: number;
	site?: string;
	version?: string;
}

const datadogConfig: DatadogConfig = {
	clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
	env: import.meta.env.MODE,
	service: 'web',
	sessionSampleRate: 100,
	site: 'datadoghq.com',
	version: APP_VERSION,
};

const ignoredStatusCodes = [0, 404];

const initDatadogRum = (config: DatadogConfig) => {
	datadogRum.init({
		...config,
		allowedTracingUrls: [
			{
				match: import.meta.env.VITE_API_BASE,
				propagatorTypes: ['datadog'],
			},
		],
		applicationId: import.meta.env.VITE_DATADOG_RUM_APP_ID,
		trackLongTasks: true,
		trackResources: true,
		trackUserInteractions: true,
		sessionReplaySampleRate: 100,
		defaultPrivacyLevel: 'mask-user-input',
		beforeSend(event) {
			if (event.type === 'resource' && event.resource.type === 'xhr' && event.resource.status_code && ignoredStatusCodes.includes(event.resource.status_code)) {
				return false;
			}
			return true;
		},
	});
	datadogRum.setGlobalContext({ region: import.meta.env.VITE_AWS_REGION, e2eEnvironment: import.meta.env.VITE_ENVIRONMENT_IS_E2E ?? false });
};

const initDatadogLogs = (config: DatadogConfig) => {
	datadogLogs.init({
		...config,
		forwardErrorsToLogs: true,
		beforeSend: (log) => {
			if (log.http && ignoredStatusCodes.includes(log.http.status_code)) return false;
			return true;
		},
	});

	window.onerror = function (message, source, lineno, colno, error) {
		datadogLogs.logger.error(error?.message || '', {
			error: { stack: error?.stack },
		});
	};
};

const setDataDogUser = (userId: string) => {
	if (import.meta.env.MODE === 'development') return;
	datadogRum.setUser({ id: userId });
};

const clearDataDogUser = () => {
	if (import.meta.env.MODE === 'development') return;
	datadogRum.clearUser();
};

const initDatadog = () => {
	if (import.meta.env.MODE === 'development') return;
	initDatadogRum(datadogConfig);
	initDatadogLogs(datadogConfig);
};

export { clearDataDogUser, initDatadog, setDataDogUser };
