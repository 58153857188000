import { computed, reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import { cloneDeep, isEqual } from 'lodash-es';
import type {
	MediaRequestFilters,
	MediaRequestSort,
	MediaRequestParams,
	MediaGalleryDetailed,
} from '@/types/media';

type MediaFilters = Omit<MediaRequestFilters, 'content_keywords'>;
type MediaPermissionBasedFilters = Pick<MediaRequestFilters, 'content_keywords'>;

export const useMediaStore = defineStore('media', () => {
	const DEFAULT_MEDIA_REQUEST_FILTERS: MediaFilters = {
		tagged: undefined,
		tag_type_ids: undefined,
	};
	const mediaRequestFilters = reactive<MediaFilters>(cloneDeep(DEFAULT_MEDIA_REQUEST_FILTERS));

	const DEFAULT_MEDIA_REQUEST_PERMISSION_BASED_FILTERS: MediaPermissionBasedFilters = {
		content_keywords: undefined,
	};
	const mediaRequestPermissionBasedFilters = reactive<MediaPermissionBasedFilters>(cloneDeep(DEFAULT_MEDIA_REQUEST_PERMISSION_BASED_FILTERS));

	const DEFAULT_MEDIA_REQUEST_SORT: MediaRequestSort = [['created_at', 'desc']];
	const mediaRequestSort = ref<MediaRequestSort>(DEFAULT_MEDIA_REQUEST_SORT);

	const setMediaRequestFilters = (filters: MediaFilters) => {
		Object.assign(mediaRequestFilters, filters);
	};

	const setMediaRequestPermissionBasedFilters = (filters: MediaPermissionBasedFilters) => {
		Object.assign(mediaRequestPermissionBasedFilters, filters);
	};

	const setMediaRequestSort = (sort: MediaRequestSort) => {
		mediaRequestSort.value = sort;
	};

	const resetMediaRequestParams = () => {
		setMediaRequestFilters(DEFAULT_MEDIA_REQUEST_FILTERS);
		setMediaRequestPermissionBasedFilters(DEFAULT_MEDIA_REQUEST_PERMISSION_BASED_FILTERS);
		setMediaRequestSort(DEFAULT_MEDIA_REQUEST_SORT);
	};

	const mediaRequestParams = computed<Omit<MediaRequestParams, 'belongs_to' | 'belongs_to_id'>>(() => ({
		sort: mediaRequestSort.value,
		...mediaRequestFilters,
		...mediaRequestPermissionBasedFilters,
	}));

	const hasActiveFilters = computed(() => {
		return !isEqual(mediaRequestFilters, DEFAULT_MEDIA_REQUEST_FILTERS);
	});

	const cachedMedia = ref<MediaGalleryDetailed>();

	const setCachedMedia = (media: MediaGalleryDetailed) => {
		cachedMedia.value = media;
	};

	const getCachedMedia = (mediaId?: string) => {
		return computed<MediaGalleryDetailed | undefined>(() => {
			if (cachedMedia.value?.uuid !== mediaId) return undefined;
			return cachedMedia.value;
		});
	};

	const $reset = () => {
		Object.assign(mediaRequestFilters, cloneDeep(DEFAULT_MEDIA_REQUEST_FILTERS));
		Object.assign(mediaRequestPermissionBasedFilters, cloneDeep(DEFAULT_MEDIA_REQUEST_PERMISSION_BASED_FILTERS));
		mediaRequestSort.value = DEFAULT_MEDIA_REQUEST_SORT;
		cachedMedia.value = undefined;
	};

	return {
		DEFAULT_MEDIA_REQUEST_FILTERS,
		DEFAULT_MEDIA_REQUEST_SORT,
		setMediaRequestFilters,
		setMediaRequestPermissionBasedFilters,
		setMediaRequestSort,
		resetMediaRequestParams,
		mediaRequestFilters,
		mediaRequestSort,
		mediaRequestParams,
		hasActiveFilters,
		setCachedMedia,
		getCachedMedia,
		$reset,
	};
});
