import { ref, reactive, watch } from 'vue';
import type { Media } from '@/types/media';
import type { ExchangeStatus, Transaction, TransactionActionKey, TransactionListTransaction } from '@/types/transactions';

interface UseTransactionDrawerState {
	isOpen: boolean;
	activeTransactionUuid: string | undefined;
	actions: TransactionActionKey[] | undefined;
	reviewsRemaining: number | undefined;

	onAddToList: ((transaction: Transaction | TransactionListTransaction) => void) | undefined;
	onSendMessage: ((transaction: Transaction | TransactionListTransaction) => void) | undefined;
	onPreviewMedia: ((payload: { index: number; media: Media[] }) => void) | undefined;

	onArchiveSuccess: (() => void) | undefined;
	onUpdateStatusSuccess: ((payload: ExchangeStatus) => void) | undefined;
	onTaskUpdated: (() => void) | undefined;

	onReviewed: (() => void) | undefined;
}

const DEFAULT_STATE: UseTransactionDrawerState = {
	isOpen: false,
	activeTransactionUuid: undefined,
	actions: undefined,
	reviewsRemaining: undefined,
	onAddToList: undefined,
	onSendMessage: undefined,
	onPreviewMedia: undefined,
	onArchiveSuccess: undefined,
	onUpdateStatusSuccess: undefined,
	onTaskUpdated: undefined,
	onReviewed: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseTransactionDrawerState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseTransactionDrawerOptions {
	actions?: TransactionActionKey[];
	onAddToList?: (transaction: Transaction | TransactionListTransaction) => void;
	onSendMessage?: (transaction: Transaction | TransactionListTransaction) => void;
	onPreviewMedia?: (payload: { index: number; media: Media[] }) => void;
	onArchiveSuccess?: () => void;
	onUpdateStatusSuccess?: (payload: ExchangeStatus) => void;
	onTaskUpdated?: () => void;
	onReviewed?: () => void;
}

export function useTransactionDrawer(options?: UseTransactionDrawerOptions) {
	async function open(uuid?: string, openOptions?: {
		reviewsRemaining?: number;
	}) {
		state.isOpen = true;
		state.activeTransactionUuid = uuid;
		state.actions = options?.actions;
		state.reviewsRemaining = openOptions?.reviewsRemaining;

		state.onPreviewMedia = options?.onPreviewMedia;
		state.onSendMessage = options?.onSendMessage;
		state.onAddToList = (transaction) => {
			state.isOpen = false;
			options?.onAddToList?.(transaction);
		};

		state.onArchiveSuccess = options?.onArchiveSuccess;
		state.onUpdateStatusSuccess = options?.onUpdateStatusSuccess;
		state.onTaskUpdated = options?.onTaskUpdated;

		state.onReviewed = options?.onReviewed;
	}

	return {
		state,
		isRendered,
		resetState,
		open,
	};
}
