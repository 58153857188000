import { isAxiosError, type AxiosResponse } from 'axios';
import { useMainStore } from '@/stores/mainStore';
import { i18n } from '@/plugins/i18n';
import { camelCase } from 'lodash-es';

const { t } = i18n.global;

export interface ApiResponse<T> {
	data: T;
	status: number;
}

export interface ApiErrorResponse {
	message: string | null;
	status: number | null;
}

export interface ApiErrorFullResponse<T> extends ApiErrorResponse {
	data?: T;
}

function handleResponse<T>(response: AxiosResponse<T, any>): ApiResponse<T> {
	return {
		data: response.data,
		status: response.status,
	};
}

const inflcrErrorCodes = [
	'access_user_already_confirmed',
	'access_user_confirmation_code_is_expired',
	'access_user_not_confirmed',
	'access_user_deactivated',
	'access_unknown_error',
	'access_invalid_user',
	'access_invalid_sso_user',
	'access_user_has_no_profiles',
	'access_user_profile_context_required',
	'content_provider_error',
	'delete_failed',
	'gallery_cannot_be_both_public_and_publicly_tagged',
	'invalid_state_transition',
	'media_not_added',
	'provider_token_is_expired',
	'update_failed',
	'payment_gateway_resource_already_exists',
	'exchange_brand_cannot_create_new_bulletin',
	'existing_conflict',
	'default',
] as const;
type InflcrErrorCode = typeof inflcrErrorCodes[number];

interface InflcrError {
	code: InflcrErrorCode;
	message: string;
}

function handleAxiosErrorCode(code?: string): void {
	if (!code) return;
	const mainStore = useMainStore();
	const axiosErrorCodes: Record<typeof code, () => void> = {
		ERR_CANCELED: () => {},
		ERR_NETWORK: () => {
			mainStore.setToast({
				type: 'error',
				title: t('axiosErrors.errNetwork.title'),
				description: t('axiosErrors.errNetwork.message'),
			});
		},
	};
	axiosErrorCodes[code] && axiosErrorCodes[code]();
}

function handleInflcrError(error: InflcrError): void {
	const camelCode = inflcrErrorCodes.includes(error.code) ? camelCase(error.code) : 'default';
	const mainStore = useMainStore();
	mainStore.setToast({
		type: 'error',
		title: t(`inflcrErrorCodes.${camelCode}.title`),
		description: t(`inflcrErrorCodes.${camelCode}.message`),
	});
}

function handleGenericError(status: number): void {
	const mainStore = useMainStore();
	const genericErrorCodes: Record<number, () => void> = {
		404: () => {
			mainStore.setToast({
				type: 'error',
				title: t('genericErrorCodes.404.title'),
				description: t('genericErrorCodes.404.message'),
			});
		},
	};
	if (genericErrorCodes[status]) {
		genericErrorCodes[status]();
	} else {
		mainStore.setToast({
			type: 'error',
			title: t('inflcrErrorCodes.default.title'),
			description: t('inflcrErrorCodes.default.message'),
		});
	}
}

function handleError<T>(error: unknown): ApiErrorFullResponse<T> {
	let message: string | null = null;
	let status: number | null = null;
	let data: T | undefined;

	if (isAxiosError(error)) {
		message = error.message;
		if (error.response) {
			if (error.response.data.message) {
				message = error.response.data.message;
			}
			status = error.response.status;
			data = error.response.data;
			if (!error.config?.bypassErrorToast) {
				error.response.data.code
					? handleInflcrError(error.response.data)
					: handleGenericError(error.response.status);
			}
		} else {
			if (!error.config?.bypassErrorToast) handleAxiosErrorCode(error.code);
		}
	}

	return { message, status, data };
}

export {
	handleResponse,
	handleError,
};
