import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/profile',
		name: 'Profile',
		component: () => import('@/views/ProfileIndex.vue'),
		meta: {
			layout: 'LayoutAuthenticated',
			layoutOptions: {
				hideAppBar: true,
			},
			requiresAuth: true,
			title: 'pageProfile.metaTitle',
		},
	},
	{
		path: '/profile/account',
		redirect: { name: 'Profile' },
	},
];

export default routes;
