import { ref, reactive, watch } from 'vue';
import { useBulletinAsync } from '@/composables';
import type { SingleExchangeBulletin } from '@/types/bulletins';

interface UseBulletinCrudModalState {
	isOpen: boolean;
	activeBulletin: SingleExchangeBulletin | undefined;
	onBulletinUpdated: (() => void) | undefined;
}

const DEFAULT_STATE: UseBulletinCrudModalState = {
	isOpen: false,
	activeBulletin: undefined,
	onBulletinUpdated: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseBulletinCrudModalState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseBulletinCrudModalOptions {
	onUpdated?: () => void;
}

export function useBulletinCrudModal(options?: UseBulletinCrudModalOptions) {
	async function open(bulletinOrUuid?: SingleExchangeBulletin | string) {
		state.isOpen = true;
		state.onBulletinUpdated = options?.onUpdated;

		if (typeof bulletinOrUuid === 'string') {
			const {
				bulletin,
				getBulletin,
			} = useBulletinAsync(bulletinOrUuid, false);

			await getBulletin();

			state.activeBulletin = bulletin.value ?? undefined;
		} else {
			state.activeBulletin = bulletinOrUuid;
		}
	}

	return {
		state,
		isRendered,
		resetState,
		open,
	};
}
