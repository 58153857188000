<template>
	<div
		class="sub-layout-tile"
		data-testid="elementSubLayoutTile"
	>
		<slot />
	</div>
</template>

<style lang="scss" scoped>
@use 'sass:map';
@use '@/styles/settings.scss';

$cols-per-bp: (
	sm-and-up: 2,
	md-and-up: 3,
	lg-and-up: 4,
	xl-and-up: 5,
);

.sub-layout-tile {
	display: grid;
	grid-gap: settings.$grid-gutter;
	grid-template-columns: repeat(1, 1fr);

	@each $bp, $cols in $cols-per-bp {
		@media #{map-get(settings.$display-breakpoints, $bp)} {
			grid-template-columns: repeat($cols, 1fr);
		}
	}
}
</style>
