import type { ListResponseCursor, SortDirection } from '@/api/utilities/provider';
import type { AcademicYear } from './academicYears';
import type { ApiMediaAttachment, Media } from './media';
import type { Sport } from './sports';

const BULLETIN_CATEGORIES = {
	BRAND_AMBASSADOR: 1,
	CHARITY: 2,
	COLLECTIVE_OPT_IN: 3,
	CONTENT_CREATION: 4,
	DIGITAL_ASSETS: 5,
	EMPLOYMENT_INTERNSHIPS: 6,
	GENERAL: 7,
	GROUP_LICENSING: 8,
	MERCHANDISE_AUTOGRAPHS: 9,
	PUBLIC_APPEARANCE: 10,
} as const;

type BulletinCategory = typeof BULLETIN_CATEGORIES[keyof typeof BULLETIN_CATEGORIES];

const BULLETIN_TYPES = {
	INFORMATIONAL: 1,
	OFFER: 2,
} as const;

type BulletinType = typeof BULLETIN_TYPES[keyof typeof BULLETIN_TYPES];

interface BulletinReach {
	count: number;
}

interface BulletinBase {
	uuid: string;
	name: string;
	logo: string | null;
	is_archived: boolean;
	is_featured: boolean;
	created_at: string;
	updated_at: string;
	category: BulletinCategory;
}

interface ExchangeBulletin extends BulletinBase {
	post_type: BulletinType;
	applications: number;
	views: number;
}

interface SingleExchangeBulletin extends BulletinBase {
	description: string;
	type: BulletinType;
	url: string;
	visibility: {
		sport_types: Sport[];
		schools: { uuid: string; name: string }[];
		academic_year_ids: AcademicYear[];
	};
	questions: { uuid: string; question: string }[];
	attachments: Media[];
}

type BulletinApplicantsSortValue = 'last_name' | 'created_at';

interface BulletinApplicant {
	uuid: string;
	user: {
		uuid: string;
		first_name: string;
		last_name: string;
		profile_image: string;
	};
	team?: {
		name: string;
	};
	is_approval_required_for_payment: boolean;
	created_at: string;
	updated_at: string;
}

interface BulletinApplicantQuestionAnswer {
	question: string;
	answer: string;
}

interface BulletinApplicantExpanded extends BulletinApplicant {
	question_answers: BulletinApplicantQuestionAnswer[];
	exchange_bulletin: Omit<SingleExchangeBulletin, 'visibility' | 'questions' >;
}

const isBulletinApplicantExpanded = (data: BulletinApplicant | BulletinApplicantExpanded): data is BulletinApplicantExpanded => {
	return 'question_answers' in data;
};

type SingleBulletinApplicant = {
	applicationUuid: string;
	fullName: string | null;
	profileImage: string | null;
	applicantUuid: string | null;
	teamName: string;
	isApprovalRequiredForPayment: boolean;
	createdAt: string;
	updatedAt: string;
	questionAnswers: BulletinApplicantQuestionAnswer[];
};

interface GetBulletinsRequestParams {
	cursor: string;
}

type GetBulletinsResponse = ListResponseCursor<ExchangeBulletin>;

type GetSingleBulletinResponse = {
	data: SingleExchangeBulletin;
};

interface GetBulletinReachParams {
	sport_type_ids?: Sport[];
	school_uuids?: string[];
	academic_year_ids?: AcademicYear[];
}

interface ApiCreateBulletinRequestModel {
	name: string;
	description?: string;
	type: BulletinType;
	category: BulletinCategory;
	url: string;
	is_featured: boolean;
	visibility: {
		school_uuids: string[];
		sport_type_ids: Sport[];
		academic_year_ids: AcademicYear[];
	};
	questions: string[];
	logo?: ApiMediaAttachment;
	attachments?: ApiMediaAttachment[];
}

interface GetSingleBulletinApplicantResponse {
	data: BulletinApplicantExpanded;
}

interface GetBulletinApplicantsRequestParams {
	sort?: [BulletinApplicantsSortValue, SortDirection][];
	page?: number;
	per_page?: number;
	search?: string;
}

class SingleBulletinApplicantProfile implements SingleBulletinApplicant {
	applicationUuid;
	fullName;
	profileImage;
	applicantUuid;
	teamName;
	isApprovalRequiredForPayment;
	createdAt;
	updatedAt;
	questionAnswers;

	constructor(data: BulletinApplicant | BulletinApplicantExpanded) {
		this.applicationUuid = data.uuid;
		this.fullName = data.user ? `${data.user.first_name} ${data.user.last_name}` : null;
		this.profileImage = data.user?.profile_image;
		this.applicantUuid = data.user?.uuid;
		this.teamName = data.team ? data.team.name : '';
		this.isApprovalRequiredForPayment = data.is_approval_required_for_payment;
		this.createdAt = data.created_at;
		this.updatedAt = data.updated_at;
		this.questionAnswers = [] as BulletinApplicantQuestionAnswer[];
		if (isBulletinApplicantExpanded(data)) {
			this.questionAnswers = data.question_answers;
		}
	}
}

export {
	BULLETIN_CATEGORIES,
	BULLETIN_TYPES,

	type BulletinBase,
	type BulletinCategory,
	type BulletinType,
	type BulletinReach,
	type ExchangeBulletin,
	type BulletinApplicant,
	type BulletinApplicantExpanded,
	type BulletinApplicantsSortValue,
	type SingleExchangeBulletin,
	type SingleBulletinApplicant,
	type GetBulletinsRequestParams,
	type GetBulletinsResponse,
	type GetSingleBulletinResponse,
	type GetBulletinReachParams,
	type ApiCreateBulletinRequestModel,
	type GetSingleBulletinApplicantResponse,
	type GetBulletinApplicantsRequestParams,

	SingleBulletinApplicantProfile,
};
