import { unref, type MaybeRef } from 'vue';
import router from '@/router';
import { useAsyncState } from '@vueuse/core';
import { useBulletinsService } from '@/api';
import { useExchangeBulletinStore } from '@/stores/exchangeBulletinStore';
import { isApiError } from '@/api/utilities/provider';
import type { SingleExchangeBulletin } from '@/types/bulletins';

export function useBulletinAsync(
	bulletinUuid: MaybeRef<string | undefined>,
	redirectOnError: boolean = true,
) {
	const exchangeBulletinsService = useBulletinsService();
	const exchangeBulletinStore = useExchangeBulletinStore();

	const getExchangeBulletinPromise = async (uuid: MaybeRef<string | undefined>): Promise<SingleExchangeBulletin | null> => {
		const localUuid = unref(uuid);
		if (!localUuid) return null;
		try {
			const bulletin = await exchangeBulletinsService.getBulletin(localUuid);
			exchangeBulletinStore.setCachedBulletin(bulletin);
			return bulletin;
		} catch (error) {
			if (redirectOnError && isApiError(error)) {
				error.status === 403
					? router.replace({ name: 'NoAccess' })
					: router.replace({ name: 'NotFound' });
			}
			return null;
		}
	};

	const {
		state: bulletin,
		isLoading: isBulletinLoading,
		execute: getBulletin,
	} = useAsyncState(
		() => getExchangeBulletinPromise(bulletinUuid),
		exchangeBulletinStore.getCachedBulletin(unref(bulletinUuid))?.value,
		{ immediate: false, onError: (error) => { throw error; } },
	);

	return {
		bulletin,
		isBulletinLoading,
		getBulletin,
	};
}
