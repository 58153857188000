<template>
	<VAvatar
		ref="baseAvatar"
		class="base-avatar"
		color="white"
		:size="size"
	>
		<slot name="default">
			<VImg
				:src="imgSrc"
				:alt="alt"
				cover
			>
				<template #placeholder>
					<div class="d-flex align-center justify-center fill-height">
						<VIcon
							color="primary"
							:icon="icon"
							:size="size"
						/>
					</div>
				</template>
			</VImg>
		</slot>
	</VAvatar>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
	alt?: string;
	src: string | null;
	size: number | string;
	icon?: string;
}>(), {
	alt: '',
	src: null,
	icon: '$twi-avatar',
});

const imgSrc = computed(() => props.src ?? undefined);
</script>
