import { i18n } from '@/plugins/i18n';
import { sortBy, camelCase } from 'lodash-es';
import { SPORTS, type Sport } from '@/types/sports';

const sports = sortBy(Object.keys(SPORTS).map((key) => ({
	id: SPORTS[key as keyof typeof SPORTS],
	name: i18n.global.t(`sports.${camelCase(key)}`),
})), 'name');

const sportsObj = sports.reduce((obj, sport) => {
	obj[sport.id] = sport.name;
	return obj;
}, {} as { [key in Sport]: string });

export {
	sports,
	sportsObj,
};
