<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.3832 0.372019L17.6168 3.98228C18.4731 4.48013 19 5.39587 19 6.38792V13.6103C19 14.6041 18.4731 15.5199 17.6168 16.0159L11.3832 19.628C10.5269 20.124 9.47314 20.124 8.61678 19.628L2.38322 16.0159C1.52686 15.5199 1 14.6041 1 13.6103V6.38792C1 5.39587 1.52686 4.48013 2.38322 3.98228L8.61678 0.372019C9.47314 -0.124006 10.5269 -0.124006 11.3832 0.372019Z"
			fill="#462EC2"
		/>
		<path
			d="M12.6667 7.33331H7.33335C6.96335 7.33331 6.67002 7.62998 6.67002 7.99998L6.66669 12C6.66669 12.37 6.96335 12.6666 7.33335 12.6666H12.6667C13.0367 12.6666 13.3334 12.37 13.3334 12V7.99998C13.3334 7.62998 13.0367 7.33331 12.6667 7.33331ZM12.3334 12H7.66669C7.48335 12 7.33335 11.85 7.33335 11.6666V9.99998H12.6667V11.6666C12.6667 11.85 12.5167 12 12.3334 12ZM12.6667 8.66665H7.33335V7.99998H12.6667V8.66665Z"
			fill="white"
		/>
	</svg>
</template>
