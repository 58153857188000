import { i18n } from '@/plugins/i18n';
import { PAYMENT_GATEWAYS } from '@/types/paymentMethods';
import { camelCase } from 'lodash-es';

const paymentGateways = Object.keys(PAYMENT_GATEWAYS).map((key) => ({
	id: PAYMENT_GATEWAYS[key as keyof typeof PAYMENT_GATEWAYS],
	name: i18n.global.t(`paymentGateways.${camelCase(key)}`),
}));

export {
	paymentGateways,
};
