import { defineStore } from 'pinia';
import { reactive, toRefs } from 'vue';
import type { Toast } from '@/composables';
import type { Conference } from '@/types/conferences';
import { useConferencesService, useSchoolsService } from '@/api';
import { cloneDeep, sortBy } from 'lodash-es';
import type { School } from '@/types/schools';

interface MainStoreState {
	isAppLoading: boolean;
	toasts: Toast[];
	schools: School[];
	conferences: Conference[];
}

export const useMainStore = defineStore('main', () => {
	const DEFAULT_STATE = {
		toasts: [],
		isAppLoading: false,
		schools: [],
		conferences: [],
	};

	const state = reactive<MainStoreState>(cloneDeep(DEFAULT_STATE));

	const abortControllers = reactive<Record<string, AbortController>>({});

	const setToast = (toast: Omit<Toast, 'uuid'>) => {
		state.toasts.push({ ...toast, uuid: crypto.randomUUID() });
	};

	const removeToast = (uuid: string) => {
		const index = state.toasts.findIndex((toast) => toast.uuid === uuid);
		if (index > -1) state.toasts.splice(index, 1);
	};

	const setConferences = async () => {
		const conferencesService = useConferencesService();
		const conferences = await conferencesService.getConferences();
		if (!conferences) throw new Error();
		state.conferences = sortBy(conferences, 'name');
	};

	const setSchools = async () => {
		const schoolsService = useSchoolsService();
		const schools = await schoolsService.getSchools({ all: 1 });
		if (!schools) throw new Error();
		state.schools = sortBy(schools, 'name');
	};

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	return {
		...toRefs(state),
		setToast,
		removeToast,
		abortControllers,
		setConferences,
		setSchools,
		$reset,
	};
});
