import type { ListResponseCursor } from '@/api/utilities/provider';
import { useUserStore } from '@/stores/userStore';
import { exchangeStatuses } from '@/utils/transactions';
import { isAfter } from 'date-fns';
import type { Media, ApiMediaAttachment } from './media';
import { type ExchangeStatus, type TransactionUnitType } from './transactions';
import type { BaseUser } from './users';

interface ExchangeConversation {
	uuid: string;
	fullName: string;
	profileImage: string | null;
	teamName: string | null;
	isContactDetailsShared: boolean;
	isReadOnly: boolean;
	athleteUuid: string;
	message?: ExchangeConversationMessage;
	isNew?: boolean;
	isDelivered?: boolean;
	isRead?: boolean;
}

interface GetExchangeConversationsParams {
	per_page?: number;
	cursor?: string;
	schools?: string[];
	is_read?: 0 | 1;
	type?: 'message' | 'card';
	last_message_sent_by?: 'brand' | 'athlete';
	between?: [string, string];
	athlete_name?: string;
}

type GetExchangeConversationsFilters = Omit<GetExchangeConversationsParams, 'per_page' | 'cursor'>;

type GetExchangeConversationsResponse = ListResponseCursor<ApiExchangeConversation>;

interface ApiExchangeConversation {
	uuid: string;
	receiving_user: ConversationUser;
	message: ApiMessage;
	athlete_has_shared_contact_details: boolean;
	new_messages_since_last_read: boolean;
	receiving_user_last_read_at: string | null;
	sending_user_last_read_at: string | null;
	read_only: boolean;
}

interface ApiMessage {
	uuid: string;
	text: string;
	text_related_model: TextRelatedModel | null;
	created_at: string;
	user: BaseUser;
}

interface ConversationUser {
	uuid: string;
	first_name: string;
	last_name: string;
	team: string | null;
	profile_image: string | null;
}

interface GetExchangeConversationResponse {
	data: ApiSingleExchangeConversation;
}

interface ApiSingleExchangeConversation {
	uuid: string;
	receiving_user_last_read_at: string | null;
	sending_user_last_read_at: string | null;
	receiving_user_accepted_at: string | null;
	first_comment_uuid: string;
	sending_user: ConversationUser;
	receiving_user: ConversationUser;
	read_only: boolean;
	athlete_has_shared_contact_details: boolean;
	new_messages_since_last_read: boolean;
}

interface GetExchangeConversationMessagesParams {
	per_page?: number;
	cursor?: string;
}

type GetExchangeConversationMessagesResponse = ListResponseCursor<ApiMessage>;

type TextRelatedModel = {
	type: 'Transaction';
	data: MessageModelTransaction;
} | {
	type: 'Media';
	data: Media;
} | {
	type: 'ExchangeBulletinApplication';
	data: MessageModelExchangeBulletinApplication;
};

interface MessageModelTransaction {
	uuid: string;
	name: string;
	cost: number;
	date: string;
	exchange_status: ExchangeStatus;
	current_exchange_status: ExchangeStatus;
	unit_type: TransactionUnitType;
}

interface MessageModelExchangeBulletinApplication {
	exchange_bulletin_application_uuid: string;
	exchange_bulletin_uuid: string;
	exchange_brand_uuid: string;
	exchange_bulletin_name: string;
}

class SingleExchangeConversation implements ExchangeConversation {
	uuid: string;
	fullName: string;
	profileImage: string | null;
	teamName: string | null;
	isReadOnly: boolean;
	isContactDetailsShared: boolean;
	athleteUuid: string;

	constructor(data: ApiExchangeConversation | ApiSingleExchangeConversation) {
		this.uuid = data.uuid;
		this.uuid = data.uuid;
		this.fullName = `${data.receiving_user.first_name} ${data.receiving_user.last_name}`;
		this.profileImage = data.receiving_user.profile_image;
		this.teamName = data.receiving_user.team;
		this.isContactDetailsShared = data.athlete_has_shared_contact_details;
		this.isReadOnly = data.read_only;
		this.athleteUuid = data.receiving_user.uuid;
	}
}

class ExchangeConversationListItem extends SingleExchangeConversation {
	isNew: boolean;
	isDelivered: boolean;
	isRead: boolean;
	message: ExchangeConversationMessage;

	constructor(data: ApiExchangeConversation) {
		super(data);
		this.message = new ExchangeConversationMessage(data.message);

		const userStore = useUserStore();
		const lastMessageSentByUser = data.message.user.uuid === userStore.user?.uuid;
		const recievingUserHasRead = data.receiving_user_last_read_at !== null
			&& isAfter(
				new Date(data.receiving_user_last_read_at),
				new Date(data.message.created_at),
			);

		this.isNew = !lastMessageSentByUser && data.new_messages_since_last_read;
		this.isDelivered = lastMessageSentByUser && !recievingUserHasRead;
		this.isRead = lastMessageSentByUser && recievingUserHasRead;
	}
}

class ExchangeConversationMessage {
	uuid: string;
	text: string;
	createdAt: string;
	textRelatedModel: TextRelatedModel | null;
	isIncoming: boolean;
	user: BaseUser;
	exchangeStatus: typeof exchangeStatuses[number] | undefined;

	constructor(data: ApiMessage) {
		this.uuid = data.uuid;
		this.createdAt = data.created_at;
		this.textRelatedModel = data.text_related_model;

		const userStore = useUserStore();
		this.isIncoming = data.user.uuid !== userStore.user?.uuid;

		const exchangeStatus = data.text_related_model?.type === 'Transaction'
			? data.text_related_model.data.exchange_status
			: undefined;
		this.exchangeStatus = exchangeStatuses.find(({ id }) => id === exchangeStatus);

		this.text = exchangeStatus
			? this.exchangeStatus?.conversationText ?? ''
			: data.text;

		this.user = data.user;
	}

	transactionText(conversation: ExchangeConversation) {
		const isFromAgent = this.isIncoming && this.user.uuid !== conversation.athleteUuid;
		if (!isFromAgent || !this.exchangeStatus) return this.text;
		return this.exchangeStatus.agentText(`${this.user.first_name} ${this.user.last_name}`, conversation.fullName);
	}
}

interface GetExchangeConversationsStatsResponse {
	data: ApiExchangeConversationsStats;
}

interface ApiExchangeConversationsStats {
	conversations_opened: number;
	messages_sent: number;
	messages_received: number;
}

interface CreateExchangeConversationsRequestModel {
	user_uuids: string[];
	text?: string;
	attachments?: ApiMediaAttachment[];
}

interface CreateExchangeConversationsResponse {
	data: {
		uuid: string;
	};
}

interface ApiAddMessageToExchangeConversationRequestModel {
	text: string;
	attachments?: ApiMediaAttachment[];
}

interface ApiAddMessageToExchangeConversationResponse {
	data: ApiMessage[];
	failed_attachments: ApiMediaAttachment[] | null;
	is_failed_text_message: boolean;
}

export {
	SingleExchangeConversation,
	ExchangeConversationListItem,
	ExchangeConversationMessage,
	type ExchangeConversation,
	type GetExchangeConversationsParams,
	type GetExchangeConversationsFilters,
	type GetExchangeConversationsResponse,
	type GetExchangeConversationResponse,
	type GetExchangeConversationMessagesParams,
	type GetExchangeConversationMessagesResponse,
	type GetExchangeConversationsStatsResponse,
	type CreateExchangeConversationsRequestModel,
	type CreateExchangeConversationsResponse,
	type ApiAddMessageToExchangeConversationRequestModel,
	type ApiAddMessageToExchangeConversationResponse,
	type ApiExchangeConversationsStats,
	type MessageModelExchangeBulletinApplication,
	type MessageModelTransaction,
	type ApiMessage,
};
