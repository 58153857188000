import { i18n } from '@/plugins/i18n';
import { sortBy, camelCase } from 'lodash-es';
import { MAJORS, type Major } from '@/types/majors';

const majors = sortBy(Object.keys(MAJORS).map((key) => ({
	id: MAJORS[key as keyof typeof MAJORS],
	name: i18n.global.t(`majors.${camelCase(key)}`),
})), 'name');

const majorsObj = majors.reduce((obj, major) => {
	obj[major.id] = major.name;
	return obj;
}, {} as { [key in Major]: string });

export {
	majors,
	majorsObj,
};
