const INTERESTS = {
	ACTING: 1,
	CHARITY: 2,
	COACHING: 3,
	CONTENT_CREATION: 4,
	CRYPTO: 5,
	FASHION: 6,
	FITNESS: 7,
	FOOD: 8,
	MOVIES: 9,
	MUSIC: 10,
	PHOTOGRAPHY: 11,
	TRAVEL: 12,
	TV_SHOWS: 13,
	VIDEO_GAMES: 14,
	MILITARY: 15,
} as const;

type Interest = typeof INTERESTS[keyof typeof INTERESTS];

export {
	INTERESTS,
	type Interest,
};
