import { i18n } from '@/plugins/i18n';
import { INTERESTS, type Interest } from '@/types/interests';
import { sortBy, camelCase } from 'lodash-es';

const interests = sortBy(Object.keys(INTERESTS).map((key) => ({
	id: INTERESTS[key as keyof typeof INTERESTS],
	name: i18n.global.t(`interests.${camelCase(key)}`),
})), 'name');

const interestsObj = interests.reduce((obj, interest) => {
	obj[interest.id] = interest.name;
	return obj;
}, {} as { [key in Interest]: string });

export {
	interests,
	interestsObj,
};
