import { createApp, markRaw } from 'vue';
import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import router from './router';
import { VueQueryPlugin } from 'vue-query';
import { initAxios } from '@/plugins/axios';
import { initDatadog } from '@/plugins/datadog';
import { i18n } from '@/plugins/i18n';
import { vuetify } from '@/plugins/vuetify';
import OktaVue from '@okta/okta-vue';
import { oktaConfig } from '@/plugins/okta';
import { initReviewApp } from '@/plugins/gitlab';
import './styles/main.scss';
import App from './App.vue';

initAxios();
initDatadog();

const app = createApp(App);
const pinia = createPinia();
pinia.use(({ store }) => {
	store.$router = markRaw(router);
});

app.use(pinia);
app.use(router);
app.use(createHead());
app.use(i18n);
app.use(vuetify);
app.use(VueQueryPlugin);
app.use(OktaVue, oktaConfig);
app.mount('#app');

initReviewApp();
