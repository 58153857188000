const SPORTS = {
	BASEBALL: 1,
	BASKETBALL_MENS: 2,
	BASKETBALL_WOMENS: 3,
	CROSS_COUNTRY_MENS: 4,
	CROSS_COUNTRY_WOMENS: 5,
	FIELD_HOCKEY: 6,
	FOOTBALL: 7,
	GOLF_MENS: 8,
	GOLF_WOMENS: 9,
	GYMNASTICS_MENS: 10,
	GYMNASTICS_WOMENS: 11,
	ICE_HOCKEY_MENS: 12,
	ICE_HOCKEY_WOMENS: 13,
	ROWING_MENS: 14,
	ROWING_WOMENS: 15,
	SOCCER_MENS: 16,
	SOCCER_WOMENS: 17,
	SOFTBALL: 18,
	SWIMMINGDIVING_MENS: 19,
	SWIMMINGDIVING_WOMENS: 20,
	TENNIS_MENS: 21,
	TENNIS_WOMENS: 22,
	TRACK_AND_FIELD_MENS: 23,
	TRACK_AND_FIELD_WOMENS: 24,
	VOLLEYBALL_MENS: 25,
	VOLLEYBALL_WOMENS: 26,
	WRESTLING_MENS: 27,
	WRESTLING_WOMENS: 28,
	CHEERLEADING: 29,
	ENTERTAINMENT: 30,
	BUSINESS: 31,
	MEDIA: 32,
	TRAVEL: 33,
	EVENT: 34,
	OTHER: 35,
	ADMISSIONS: 36,
	NEW_TEAMS: 37,
	SPIRIT_PROGRAM: 38,
	POLO_MENS: 39,
	POLO_WOMENS: 40,
	CROSS_COUNTRY: 41,
	SQUASH_MENS: 42,
	SQUASH_WOMENS: 43,
	WHEELCHAIR_BASKETBALL: 44,
	GENERAL: 45,
	DANCE_TEAM: 46,
	SAILING_MENS: 47,
	SAILING_WOMENS: 48,
	INDOOR_TRACK_MENS: 49,
	INDOOR_TRACK_WOMENS: 50,
	INDOOR_TRACK: 51,
	OUTDOOR_TRACK: 52,
	RIFLE: 53,
	FENCING_MENS: 54,
	FENCING_WOMENS: 55,
	BADMINTON_MENS: 56,
	BADMINTON_WOMENS: 57,
	CURLING_MENS: 58,
	CURLING_WOMENS: 59,
	CURLING: 60,
	FASTPITCH: 61,
	FENCING: 62,
	FIGURE_SKATING: 63,
	MOUNTAIN_BIKING: 64,
	NORDIC_SKIING: 65,
	ROWING: 66,
	SYNCHRONIZED_SWIMMING_WOMENS: 67,
	RIFLE_MENS: 68,
	RIFLE_WOMENS: 69,
	BOWLING_MENS: 70,
	BOWLING_WOMENS: 71,
	SKIING_MENS: 72,
	SKIING_WOMENS: 73,
	EQUESTRIAN: 74,
	WATER_POLO_MENS: 75,
	WATER_POLO_WOMENS: 76,
	ALPINE_SKIING: 77,
	RODEO_MENS: 78,
	RODEO_WOMENS: 79,
	CLUB_HOCKEY: 80,
	SAAC: 81,
	RUGBY_MENS: 82,
	RUGBY_WOMENS: 83,
	CRICKET: 84,
	CYCLING: 85,
	SYNCHRONIZED_SWIMMING: 86,
	JV_BASKETBALL_MENS: 87,
	JV_BASKETBALL_WOMENS: 88,
	VOLLEYBALL_BEACH: 89,
	TRIATHLON: 90,
	ACROBATICS_TUMBLING: 91,
	LIGHTWEIGHT_ROWING_WOMENS: 92,
	CO_ED_SQUASH: 93,
	SYNCHRONIZED_SKATING: 94,
	LACROSSE_MENS: 95,
	LACROSSE_WOMENS: 96,
	SWIMMINGDIVING: 97,
	DIVING: 98,
	TRACK_AND_FIELD: 99,
} as const;

type Sport = typeof SPORTS[keyof typeof SPORTS];

export {
	SPORTS,
	type Sport,
};
