import { storeToRefs } from 'pinia';
import { useAuthService } from '@/api';
import { useUserStore } from '@/stores/userStore';
import { type AppSwitcherSlug, APP_SWITCHER_SLUGS } from '@/types/users';

interface AppSwitcherAppMetaData {
	title: string;
	slug: AppSwitcherSlug;
	icon: string;
}

export function useAppSwitcher() {
	const authService = useAuthService();
	const { appSwitcherProfile } = storeToRefs(useUserStore());


	const appsMetaData: AppSwitcherAppMetaData[] = [
		{
			title: 'Hub',
			slug: APP_SWITCHER_SLUGS.HUB,
			icon: '$twi-app-hub',
		},
		{
			title: 'Camps',
			slug: APP_SWITCHER_SLUGS.CAMPS,
			icon: '$twi-app-camps',
		},
		{
			title: 'Inventory Management',
			slug: APP_SWITCHER_SLUGS.INVENTORY,
			icon: '$twi-app-trac',
		},
		{
			title: 'Compliance + Recruiting',
			slug: APP_SWITCHER_SLUGS.ARMS,
			icon: '$twi-app-compliance',
		},
		{
			title: 'Influencer',
			slug: APP_SWITCHER_SLUGS.INFLCR,
			icon: '$twi-app-influencer',
		},
		{
			title: 'Collectives',
			slug: APP_SWITCHER_SLUGS.COLLECTIVES,
			icon: '$twi-app-collectives',
		},
		{
			title: 'AMS',
			slug: APP_SWITCHER_SLUGS.AMS,
			icon: '$twi-app-ams',
		},
		{
			title: 'Nutrition',
			slug: APP_SWITCHER_SLUGS.NUTRITION,
			icon: '$twi-app-nutrition',
		},
		{
			title: 'Strength + Conditioning',
			slug: APP_SWITCHER_SLUGS.STRENGTH,
			icon: '$twi-app-strength',
		},
		{
			title: 'Academics',
			slug: APP_SWITCHER_SLUGS.ACADEMICS,
			icon: '$twi-app-academics',
		},
		{
			title: 'Pathways',
			slug: APP_SWITCHER_SLUGS.PATHWAYS,
			icon: '$twi-app-pathways',
		},
		{
			title: 'Pulse',
			slug: APP_SWITCHER_SLUGS.PULSE,
			icon: '$twi-app-pulse',
		},
	];



	const switchApp = async (slug: AppSwitcherSlug) => {
		const url = appSwitcherProfile?.value?.[`${slug}_target_url`];
		if (!url) {
			console.error('No URL provided');
			return;
		}
		try {
			const { token } = await authService.getOutgoingAxleToken();
			window.location.href = `${url}?token=${token}`;
		} catch (e) {
			console.error(e);
		}
	};

	return {
		appsMetaData,
		switchApp,
	};
}
