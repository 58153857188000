import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import loginRoutes from './_login';
import profileChooserRoutes from './_profile-chooser';
import releaseNotesRoutes from './_release-notes';
import contentHubRoutes from './_content-hub';
import providerRoutes from './_provider';
import rosterRoutes from './_roster';
import exchangeRoutes from './_exchange';
import profileRoutes from './_profile';
import agentRoutes from './_agent';
import accountSettingsRoutes from './_account-settings';
import ncaaRoutes from './_ncaa';
import nilRoutes from './_nil';
// end imports
import { isAuthenticated } from '@/stores/authStore';

function updateRouteMeta(to: RouteLocationNormalized) {
	const _isAuthenticated = isAuthenticated();
	to.meta.requiresAuth = _isAuthenticated;
	to.meta.layout = _isAuthenticated ? 'LayoutAuthenticated' : 'LayoutDefault';
}

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		redirect: '/login',
	},
	...loginRoutes,
	...profileChooserRoutes,
	...releaseNotesRoutes,
	...contentHubRoutes,
	...providerRoutes,
	...rosterRoutes,
	...exchangeRoutes,
	...profileRoutes,
	...agentRoutes,
	...accountSettingsRoutes,
	...ncaaRoutes,
	...nilRoutes,
	// end routes
	{
		path: '/not-found',
		name: 'NotFound',
		component: () => import('@/views/ErrorIndex.vue'),
		props: { errorType: 'notFound' },
		meta: {
			layout: isAuthenticated() ? 'LayoutAuthenticated' : 'LayoutDefault',
			requiresAuth: isAuthenticated(),
			title: 'pageError.notFound.metaTitle',
		},
		beforeEnter: (to) => updateRouteMeta(to),
	},
	{
		path: '/no-access',
		name: 'NoAccess',
		component: () => import('@/views/ErrorIndex.vue'),
		props: { errorType: 'noAccess' },
		meta: {
			layout: isAuthenticated() ? 'LayoutAuthenticated' : 'LayoutDefault',
			requiresAuth: isAuthenticated(),
			title: 'pageError.noAccess.metaTitle',
		},
		beforeEnter: (to) => updateRouteMeta(to),
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/not-found',
	},
];

export default routes;
