import { defineStore } from 'pinia';
import { reactive, toRefs, computed } from 'vue';
import { cloneDeep, isEqual } from 'lodash-es';
import { LAYOUTS, type Layout } from '@/composables';
import type { GetGalleriesRequestParams } from '@/types/galleries';

type GalleriesFilters = Omit<GetGalleriesRequestParams, 'page' | 'sort'>;
interface UseGalleriesStoreState {
	galleriesFilters: GalleriesFilters;
	galleriesSort: GetGalleriesRequestParams['sort'];
	galleriesView: Layout;
}

export const getDefaultFilters = (): GalleriesFilters => ({
	category: [],
	status: undefined,
	date: undefined,
});
export const getDefaultSort = (): GetGalleriesRequestParams['sort'] => [['date', 'desc']];


export const useGalleriesStore = defineStore('galleries', () => {
	const DEFAULT_STATE = {
		galleriesFilters: getDefaultFilters(),
		galleriesSort: getDefaultSort(),
		galleriesView: LAYOUTS.VIRTUAL_TILE,
	};

	const state = reactive<UseGalleriesStoreState>(cloneDeep(DEFAULT_STATE));

	const setGalleriesFilters = (filters: GalleriesFilters) => {
		Object.assign(state.galleriesFilters, filters);
	};

	const setGalleriesSort = (sort: GetGalleriesRequestParams['sort']) => {
		state.galleriesSort = sort;
	};

	const resetGalleriesFilters = () => {
		Object.assign(state.galleriesFilters, getDefaultFilters());
	};

	const galleriesRequestParams = computed<Omit<GetGalleriesRequestParams, 'page'>>(() => {
		return {
			...state.galleriesFilters,
			sort: state.galleriesSort,
		};
	});

	const galleriesDateRange = computed<[string, string] | undefined>(() => {
		const { date } = state.galleriesFilters;
		if (!date) return undefined;
		return [date.start, date.end];
	});

	const hasActiveFilters = computed<boolean>(() => {
		return !isEqual(state.galleriesFilters, getDefaultFilters());
	});

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	return {
		...toRefs(state),
		hasActiveFilters,
		galleriesRequestParams,
		setGalleriesFilters,
		setGalleriesSort,
		resetGalleriesFilters,
		galleriesDateRange,
		$reset,
	};
});
