import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/disclose-nil-activity',
		children: [
			{
				path: '',
				name: 'DiscloseNilActivity',
				component: () => import('@/views/DiscloseNilActivity.vue'),
				meta: {
					layout: 'LayoutAuthenticated',
					requiresAuth: true,
					title: 'pageDiscloseNilActivity.metaTitle',
					requiredAccess: 'hasTransactionsImportWrite',
				},
			},
		],
	},
];

export default routes;
