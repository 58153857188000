<template>
	<span
		v-if="isMenuVisible"
		ref="contextMenu"
	>
		<slot
			name="activator"
		/>
		<VMenu
			ref="contextMenuMenu"
			:activator="`#${activatorId}`"
			:location="location"
			:theme="theme"
		>
			<VMenuList
				ref="contextMenuList"
				@keydown.down.capture="interceptMenuNavigation"
				@keydown.up.capture="interceptMenuNavigation"
				@keydown.enter.capture="toggleSubMenu"
			>
				<VListItem
					v-for="item, index in visibleItems"
					:key="index"
					:ref="`contextMenuItem-${index}`"
					class="mb-0"
					:class="{ 'mt-1': index > 0 }"
					:disabled="unref(item.disabled)"
					:link="!!item.action || !!item.items?.length"
					:data-testid="`buttonContextMenuItem-${index}`"
					:data-id="item.dataId"
					@click="!item.items?.length && item.action?.(context)"
				>
					<template
						v-if="item.icon"
						#prepend
					>
						<VIcon
							:ref="`contextMenuItem-${index}-icon`"
							:color="unref(item.disabled) ? '#898A8B' : item.iconColor ?? '#898A8B'"
							:icon="item.icon"
						/>
					</template>
					<span
						:ref="`contextMenuItem-${index}-label`"
						class="text-body-2 font-weight-semibold"
					>
						{{ item.label }}
					</span>
					<template
						v-if="item.items?.length || item.appendedText"
						#append
					>
						<VIcon
							v-if="item.items?.length"
							:ref="`contextMenuItem-${index}-chevron`"
							icon="chevron_right"
						/>
						<span
							v-else
							:ref="`contextMenuItem-${index}-appendedText`"
							class="text-subtitle-1 text-medium-emphasis"
						>
							{{ item.appendedText }}
						</span>
					</template>
					<VMenu
						v-if="item.items?.length"
						:ref="`contextMenuItem-${index}-subMenu`"
						location="end"
						activator="parent"
					>
						<VMenuList>
							<VListItem
								v-for="subItem, subIndex in item.items"
								:key="subIndex"
								:ref="`contextMenuItem-${index}-subItem-${subIndex}`"
								class="mb-0"
								:class="{ 'mt-1': index > 0 }"
								:disabled="unref(subItem.disabled)"
								:link="!!subItem.action"
								:data-id="item.dataId"
								@click="subItem.action"
							>
								<template
									v-if="subItem.icon"
									#prepend
								>
									<VIcon
										:ref="`contextMenuItem-${index}-subItem-${subIndex}-icon`"
										color="#898A8B"
										:icon="subItem.icon"
									/>
								</template>
								<span
									:ref="`contextMenuItem-${index}-subItem-${subIndex}-label`"
									class="text-body-2 font-weight-semibold"
								>
									{{ subItem.label }}
								</span>
								<template
									v-if="subItem.appendedText"
									#append
								>
									<span class="text-subtitle-1 text-medium-emphasis">
										{{ subItem.appendedText }}
									</span>
								</template>
							</VListItem>
						</VMenuList>
					</VMenu>
				</VListItem>
			</VMenuList>
		</VMenu>
	</span>
</template>

<script setup lang="ts">
import { computed, ref, unref, type MaybeRef } from 'vue';
import { useUserStore } from '@/stores/userStore';
import type { VMenu } from 'vuetify/components/VMenu';
import type { ProfileAccessService } from '@/types/users';

export interface ContextMenuItem {
	label: string;
	icon?: string;
	iconColor?: string;
	items?: Omit<ContextMenuItem, 'items'>[];
	disabled?: MaybeRef<boolean>;
	requiredAccess?: keyof ProfileAccessService;
	visible?: MaybeRef<boolean>;
	action?: (context?: any) => void;
	appendedText?: string;
	dataId?: string;
}

const props = withDefaults(defineProps<{
	activatorId: string;
	items: ContextMenuItem[];
	location?: VMenu['location'];
	theme?: string;
	context?: any;
}>(), {
	location: 'bottom',
	theme: undefined,
	context: undefined,
});


const contextMenuList = ref();

const interceptMenuNavigation = (e: KeyboardEvent) => {
	const target = e.target as HTMLElement;
	if (target.ariaHasPopup === 'menu') {
		e.stopPropagation();
		e.preventDefault();
		if (e.key === 'ArrowDown') {
			contextMenuList.value.focus('next');
		}
		if (e.key === 'ArrowUp') {
			contextMenuList.value.focus('prev');
		}
	}
};

const toggleSubMenu = (e: KeyboardEvent) => {
	const target = e.target as HTMLElement;
	if (target.ariaHasPopup === 'menu') {
		target.click();
	}
};

const userStore = useUserStore();

const visibleItems = computed<ContextMenuItem[]>(() => props.items.filter((i) => {
	if (unref(i.visible) === false) return false;
	if (i.requiredAccess === undefined) return true;
	return userStore.profileAccess[i.requiredAccess];
}));

const isMenuVisible = computed<boolean>(() => {
	return !!visibleItems.value.length;
});
</script>
