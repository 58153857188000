import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/agent',
		redirect: { name: 'AgentAthletes' },
		children: [
			{
				path: 'athletes',
				name: 'AgentAthletes',
				component: () => import('@/views/AgentAthletesParent.vue'),
				redirect: { name: 'AgentAthletesBrowse' },
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageAgent.metaTitle',
					requiredAccess: 'hasExchangeWrite',
				},
				children: [
					{
						path: 'browse',
						name: 'AgentAthletesBrowse',
						component: () => import('@/views/AgentAthletesBrowse.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageAgentAthletesBrowse.metaTitle',
							requiredAccess: 'hasAgentWrite',
						},
					},
				],
			},
			{
				path: 'transactions',
				name: 'AgentTransactions',
				component: () => import('@/views/AgentTransactions.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageAgentTransactions.metaTitle',
					requiredAccess: 'hasAgentWrite',
				},
			},
			{
				path: 'transactions/:transactionUuid',
				name: 'AgentTransaction',
				component: () => import('@/views/AgentTransaction.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageTransaction.metaTitle',
					requiredAccess: 'hasAgentWrite',
				},
			},
		],
	},
];

export default routes;
