import type { RouteRecordRaw } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import { useRouter } from 'vue-router';
import { type Role, ROLES } from '@/types/users';

const routes: RouteRecordRaw[] = [
	{
		path: '/choose-profile',
		children: [
			{
				path: '',
				name: 'ProfileChooserIndex',
				component: () => import('@/views/ProfileChooserIndex.vue'),
				meta: {
					layout: 'LayoutDefault',
					requiresAuth: true,
					title: 'pageProfileChooser.metaTitle',
				},
				beforeEnter: async () => {
					const router = useRouter();
					const userStore = useUserStore();
					const activeRoles: Role[] = [ROLES.TEAM_USER, ROLES.BRAND_USER, ROLES.AGENT, ROLES.COMPLIANCE_OFFICER];
					const hasMultipleProfiles = userStore.userProfiles?.length !== 1;
					const redirect = router.currentRoute.value.query.redirect as string;
					if (hasMultipleProfiles || !userStore.hasUserAcceptedLatestTermsOfUse) return true;
					const profile = userStore.userProfiles![0];
					if (!activeRoles.includes(profile.role_id)) return true;
					userStore.setProfile(userStore.userProfiles![0].uuid);
					return redirect ? router.push({ path: redirect }) : router.push({ name: userStore.profileDefaultRoute });
				},
			},
		],
	},
];

export default routes;
