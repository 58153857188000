import { defineStore } from 'pinia';
import { reactive, toRefs, computed } from 'vue';
import { type GetContentKeywordsRequestParams } from '@/types/contentKeywords';
import { cloneDeep } from 'lodash-es';

interface UseContentKeywordsStoreState {
	contentKeyWordsSearch: string;
	contentKeywordsSort: GetContentKeywordsRequestParams['sort'];
}

export const getDefaultSort = (): GetContentKeywordsRequestParams['sort'] => [['media_count', 'desc']];

export const useContentKeywordsStore = defineStore('content-keywords', () => {
	const DEFAULT_STATE = {
		contentKeyWordsSearch: '',
		contentKeywordsSort: getDefaultSort(),
	};

	const state = reactive<UseContentKeywordsStoreState>(cloneDeep(DEFAULT_STATE));

	const setContentKeyWordsSearch = (search: string) => {
		state.contentKeyWordsSearch = search;
	};

	const setContentKeywordsSort = (sort: GetContentKeywordsRequestParams['sort']) => {
		state.contentKeywordsSort = sort;
	};

	const contentKeywordsRequestParams = computed<GetContentKeywordsRequestParams>(() => {
		return {
			sort: state.contentKeywordsSort,
		};
	});

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	return {
		...toRefs(state),
		contentKeywordsRequestParams,
		setContentKeywordsSort,
		setContentKeyWordsSearch,
		$reset,
	};
});
