import { defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';
import { cloneDeep } from 'lodash-es';
import { type ExchangePaymentMethodWallet } from '@/types/paymentMethods';

interface UseExchangePaymentMethodsWalletStoreState {
	paymentMethods: ExchangePaymentMethodWallet[] | undefined;
	onboarding: string | undefined;
	isSettingsBannerVisible: boolean;
}

export const useExchangePaymentMethodsWalletStore = defineStore('wallet', () => {
	const DEFAULT_STATE = {
		paymentMethods: undefined,
		onboarding: undefined,
		isSettingsBannerVisible: true,
	};

	const state = reactive<UseExchangePaymentMethodsWalletStoreState>(cloneDeep(DEFAULT_STATE));

	function setPaymentMethods(payload: ExchangePaymentMethodWallet[]) {
		state.paymentMethods = cloneDeep(payload);
	}

	function setOnboarding(payload: string) {
		state.onboarding = payload;
	}

	function setSettingsBannerVisible(isVisible: boolean) {
		state.isSettingsBannerVisible = isVisible;
	}

	function updateAccountBalance(account: ExchangePaymentMethodWallet, cost: number) {
		const paymentMethod = state.paymentMethods?.find((method) => method.id === account.id);
		if (!paymentMethod) throw new Error('Payment method not found');
		paymentMethod.balance -= cost;
	}

	const hasPaymentMethods = computed<boolean>(() => !!state.paymentMethods && state.paymentMethods.length > 0);

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	return {
		...toRefs(state),
		setPaymentMethods,
		setOnboarding,
		setSettingsBannerVisible,
		updateAccountBalance,
		hasPaymentMethods,
		$reset,
	};
});
