const MAJORS = {
	ACCOUNTING: 1,
	ACTUARIAL_SCIENCE: 2,
	ADVERTISING: 3,
	AEROSPACE_ENGINEERING: 4,
	AFRICAN_AMERICAN_STUDIES: 7,
	AFRICAN_LANGUAGES_LITERATURES_AND_LINGUISTICS: 5,
	AFRICAN_STUDIES: 6,
	AGRICULTURAL_BUSINESS_AND_MANAGEMENT: 8,
	AGRICULTURAL_ECONOMICS: 9,
	AGRICULTURAL_EDUCATION: 10,
	AGRICULTURAL_JOURNALISM: 11,
	AGRICULTURAL_MECHANIZATION: 12,
	AGRICULTURAL_TECHNOLOGY_MANAGEMENT: 13,
	AGRICULTURAL_BIOLOGICAL_ENGINEERING_AND_BIOENGINEERING: 14,
	AGRICULTURE: 15,
	AGRONOMY_AND_CROP_SCIENCE: 16,
	AIR_TRAFFIC_CONTROL: 17,
	AMERICAN_HISTORY: 18,
	AMERICAN_LITERATURE: 19,
	AMERICAN_SIGN_LANGUAGE: 20,
	AMERICAN_STUDIES: 21,
	ANATOMY: 22,
	ANCIENT_STUDIES: 23,
	ANIMAL_BEHAVIOR_AND_ETHOLOGY: 24,
	ANIMAL_SCIENCE: 25,
	ANIMATION_AND_SPECIAL_EFFECTS: 26,
	ANTHROPOLOGY: 27,
	APPLIED_MATHEMATICS: 28,
	AQUACULTURE: 29,
	AQUATIC_BIOLOGY: 30,
	ARABIC: 31,
	ARCHEOLOGY: 32,
	ARCHITECTURAL_ENGINEERING: 33,
	ARCHITECTURAL_HISTORY: 34,
	ARCHITECTURE: 35,
	ART: 36,
	ART_EDUCATION: 37,
	ART_HISTORY: 38,
	ART_THERAPY: 39,
	ARTIFICIAL_INTELLIGENCE_AND_ROBOTICS: 40,
	ASIAN_AMERICAN_STUDIES: 41,
	ASTRONOMY: 42,
	ASTROPHYSICS: 43,
	ATHLETIC_TRAINING: 44,
	ATMOSPHERIC_SCIENCE: 45,
	AUTOMOTIVE_ENGINEERING: 46,
	AVIATION: 47,
	BAKERY_SCIENCE: 48,
	BIBLICAL_STUDIES: 49,
	BIOCHEMISTRY: 50,
	BIOETHICS: 51,
	BIOLOGY: 52,
	BIOMEDICAL_ENGINEERING: 53,
	BIOMEDICAL_SCIENCE: 54,
	BIOPSYCHOLOGY: 55,
	BIOTECHNOLOGY: 56,
	BOTANY_PLANT_BIOLOGY: 57,
	BUSINESS_ADMINISTRATION_MANAGEMENT: 58,
	BUSINESS_COMMUNICATIONS: 59,
	BUSINESS_EDUCATION: 60,
	CANADIAN_STUDIES: 61,
	CARIBBEAN_STUDIES: 62,
	CELL_BIOLOGY: 63,
	CERAMIC_ENGINEERING: 64,
	CERAMICS: 65,
	CHEMICAL_ENGINEERING: 66,
	CHEMICAL_PHYSICS: 67,
	CHEMISTRY: 68,
	CHILD_CARE: 69,
	CHILD_DEVELOPMENT: 70,
	CHINESE: 71,
	CHIROPRACTIC: 72,
	CHURCH_MUSIC: 73,
	CINEMATOGRAPHY_AND_FILM_VIDEO_PRODUCTION: 74,
	CIRCULATION_TECHNOLOGY: 75,
	CIVIL_ENGINEERING: 76,
	CLASSICS: 77,
	CLINICAL_PSYCHOLOGY: 78,
	COGNITIVE_PSYCHOLOGY: 79,
	COMMUNICATION_DISORDERS: 80,
	COMMUNICATIONS_STUDIESSPEECH_COMMUNICATION_AND_RHETORIC: 81,
	COMPARATIVE_LITERATURE: 82,
	COMPUTER_AND_INFORMATION_SCIENCE: 83,
	COMPUTER_ENGINEERING: 84,
	COMPUTER_GRAPHICS: 85,
	COMPUTER_SYSTEMS_ANALYSIS: 86,
	CONSTRUCTION_MANAGEMENT: 87,
	COUNSELING: 88,
	CRAFTS: 89,
	CREATIVE_WRITING: 90,
	CRIMINAL_SCIENCE: 91,
	CRIMINOLOGY: 92,
	CULINARY_ARTS: 93,
	DANCE: 94,
	DATA_PROCESSING: 95,
	DENTAL_HYGIENE: 96,
	DEVELOPMENTAL_PSYCHOLOGY: 97,
	DIAGNOSTIC_MEDICAL_SONOGRAPHY: 98,
	DIETETICS: 99,
	DIGITAL_COMMUNICATIONS_AND_MEDIAMULTIMEDIA: 100,
	DRAWING: 101,
	EARLY_CHILDHOOD_EDUCATION: 102,
	EAST_ASIAN_STUDIES: 103,
	EAST_EUROPEAN_STUDIES: 104,
	ECOLOGY: 105,
	ECONOMICS: 106,
	EDUCATION: 107,
	EDUCATION_ADMINISTRATION: 108,
	EDUCATION_OF_THE_DEAF: 109,
	EDUCATIONAL_PSYCHOLOGY: 110,
	ELECTRICAL_ENGINEERING: 111,
	ELEMENTARY_EDUCATION: 112,
	ENGINEERING_MECHANICS: 113,
	ENGINEERING_PHYSICS: 114,
	ENGLISH: 115,
	ENGLISH_COMPOSITION: 116,
	ENGLISH_LITERATURE: 117,
	ENTOMOLOGY: 118,
	ENTREPRENEURSHIP: 119,
	ENVIRONMENTAL_DESIGNARCHITECTURE: 120,
	ENVIRONMENTAL_SCIENCE: 121,
	ENVIRONMENTAL_ENVIRONMENTAL_HEALTH_ENGINEERING: 122,
	EPIDEMIOLOGY: 123,
	EQUINE_STUDIES: 124,
	ETHNIC_STUDIES: 125,
	EUROPEAN_HISTORY: 126,
	EXPERIMENTAL_PATHOLOGY: 127,
	EXPERIMENTAL_PSYCHOLOGY: 128,
	FASHION_DESIGN: 129,
	FASHION_MERCHANDISING: 130,
	FEED_SCIENCE: 131,
	FIBER_TEXTILES_AND_WEAVING_ARTS: 132,
	FILM: 133,
	FINANCE: 134,
	FLORICULTURE: 135,
	FOOD_SCIENCE: 136,
	FORENSIC_SCIENCE: 137,
	FORESTRY: 138,
	FRENCH: 139,
	FURNITURE_DESIGN: 140,
	GAME_DESIGN: 141,
	GAY_AND_LESBIAN_STUDIES: 142,
	GENETICS: 143,
	GEOGRAPHY: 144,
	GEOLOGICAL_ENGINEERING: 145,
	GEOLOGY: 146,
	GEOPHYSICS: 147,
	GERMAN: 148,
	GERONTOLOGY: 149,
	GOVERNMENT: 150,
	GRAPHIC_DESIGN: 151,
	HEALTH_ADMINISTRATION: 152,
	HEBREW: 153,
	HISPANIC_AMERICAN_PUERTO_RICAN_AND_CHICANO_STUDIES: 154,
	HISTORIC_PRESERVATION: 155,
	HISTORY: 156,
	HOME_ECONOMICS: 157,
	HORTICULTURE: 158,
	HOSPITALITY: 159,
	HUMAN_DEVELOPMENT: 160,
	HUMAN_RESOURCES_MANAGEMENT: 161,
	ILLUSTRATION: 162,
	INDUSTRIAL_DESIGN: 163,
	INDUSTRIAL_ENGINEERING: 164,
	INDUSTRIAL_MANAGEMENT: 165,
	INDUSTRIAL_PSYCHOLOGY: 166,
	INFORMATION_TECHNOLOGY: 167,
	INTERIOR_ARCHITECTURE: 168,
	INTERIOR_DESIGN: 169,
	INTERNATIONAL_AGRICULTURE: 170,
	INTERNATIONAL_BUSINESS: 171,
	INTERNATIONAL_RELATIONS: 172,
	INTERNATIONAL_STUDIES: 173,
	ISLAMIC_STUDIES: 174,
	ITALIAN: 175,
	JAPANESE: 176,
	JAZZ_STUDIES: 177,
	JEWELRY_AND_METALSMITHING: 178,
	JEWISH_STUDIES: 179,
	JOURNALISM: 180,
	KINESIOLOGY: 181,
	KOREAN: 182,
	LAND_USE_PLANNING_AND_MANAGEMENT: 183,
	LANDSCAPE_ARCHITECTURE: 184,
	LANDSCAPE_HORTICULTURE: 185,
	LATIN_AMERICAN_STUDIES: 186,
	LIBRARY_SCIENCE: 187,
	LINGUISTICS: 188,
	LOGISTICS_MANAGEMENT: 189,
	MANAGEMENT_INFORMATION_SYSTEMS: 190,
	MANAGERIAL_ECONOMICS: 191,
	MARINE_BIOLOGY: 192,
	MARINE_SCIENCE: 193,
	MARKETING: 194,
	MASS_COMMUNICATION: 195,
	MASSAGE_THERAPY: 196,
	MATERIALS_SCIENCE: 197,
	MATHEMATICS: 198,
	MECHANICAL_ENGINEERING: 199,
	MEDICAL_TECHNOLOGY: 200,
	MEDIEVAL_AND_RENAISSANCE_STUDIES: 201,
	MENTAL_HEALTH_SERVICES: 202,
	MERCHANDISING_AND_BUYING_OPERATIONS: 203,
	METALLURGICAL_ENGINEERING: 204,
	MICROBIOLOGY: 205,
	MIDDLE_EASTERN_STUDIES: 206,
	MILITARY_SCIENCE: 207,
	MINERAL_ENGINEERING: 208,
	MISSIONS: 209,
	MODERN_GREEK: 210,
	MOLECULAR_BIOLOGY: 211,
	MOLECULAR_GENETICS: 212,
	MORTUARY_SCIENCE: 213,
	MUSEUM_STUDIES: 214,
	MUSIC: 215,
	MUSIC_EDUCATION: 216,
	MUSIC_HISTORY: 217,
	MUSIC_MANAGEMENT: 218,
	MUSIC_THERAPY: 219,
	MUSICAL_THEATER: 220,
	NATIVE_AMERICAN_STUDIES: 221,
	NATURAL_RESOURCES_CONSERVATION: 222,
	NAVAL_ARCHITECTURE: 223,
	NEUROBIOLOGY: 224,
	NEUROSCIENCE: 225,
	NUCLEAR_ENGINEERING: 226,
	NURSING: 227,
	NUTRITION: 228,
	OCCUPATIONAL_THERAPY: 229,
	OCEAN_ENGINEERING: 230,
	OCEANOGRAPHY: 231,
	OPERATIONS_MANAGEMENT: 232,
	ORGANIZATIONAL_BEHAVIOR_STUDIES: 233,
	PAINTING: 234,
	PALEONTOLOGY: 235,
	PASTORAL_STUDIES: 236,
	PEACE_STUDIES: 237,
	PETROLEUM_ENGINEERING: 238,
	PHARMACOLOGY: 239,
	PHARMACY: 240,
	PHILOSOPHY: 241,
	PHOTOGRAPHY: 242,
	PHOTOJOURNALISM: 243,
	PHYSICAL_EDUCATION: 244,
	PHYSICAL_THERAPY: 245,
	PHYSICIAN_ASSISTANT: 246,
	PHYSICS: 247,
	PHYSIOLOGICAL_PSYCHOLOGY: 248,
	PIANO: 249,
	PLANETARY_SCIENCE: 250,
	PLANT_PATHOLOGY: 251,
	PLAYWRITING_AND_SCREENWRITING: 252,
	POLITICAL_COMMUNICATION: 253,
	POLITICAL_SCIENCE: 254,
	PORTUGUESE: 255,
	PRE_DENTISTRY: 256,
	PRE_LAW: 257,
	PRE_MEDICINE: 258,
	PRE_OPTOMETRY: 259,
	PRE_SEMINARY: 260,
	PRE_VETERINARY_MEDICINE: 261,
	PRINTMAKING: 262,
	PSYCHOLOGY: 263,
	PUBLIC_ADMINISTRATION: 264,
	PUBLIC_HEALTH: 265,
	PUBLIC_POLICY_ANALYSIS: 266,
	PUBLIC_RELATIONS: 267,
	RADIO_AND_TELEVISION: 268,
	RADIOLOGIC_TECHNOLOGY: 269,
	RANGE_SCIENCE_AND_MANAGEMENT: 270,
	REAL_ESTATE_REAL_ESTATE_RR_TS_TECHNOLOGY: 271,
	RECREATION_MANAGEMENT: 272,
	REHABILITATION_SERVICES: 273,
	RELIGIOUS_STUDIES: 274,
	RESPIRATORY_THERAPY: 275,
	RISK_MANAGEMENT: 276,
	RURAL_SOCIOLOGY: 277,
	RUSSIAN: 278,
	SCANDINAVIAN_STUDIES: 279,
	SCULPTURE: 280,
	SLAVIC_LANGUAGES_AND_LITERATURES: 281,
	SOCIAL_PSYCHOLOGY: 282,
	SOCIAL_WORK: 283,
	SOCIOLOGY: 284,
	SOIL_SCIENCE: 285,
	SOUND_ENGINEERING: 286,
	SOUTH_ASIAN_STUDIES: 287,
	SOUTHEAST_ASIA_STUDIES: 288,
	SPANISH: 289,
	SPECIAL_EDUCATION: 290,
	SPEECH_PATHOLOGY: 291,
	SPORT_AND_LEISURE_STUDIES: 292,
	SPORTS_MANAGEMENT: 293,
	STATISTICS: 294,
	SURVEYING: 295,
	SUSTAINABLE_RESOURCE_MANAGEMENT: 296,
	TEACHER_EDUCATION: 297,
	TEACHING_ENGLISH_AS_A_SECOND_LANGUAGE: 298,
	TECHNICAL_WRITING: 299,
	TECHNOLOGY_EDUCATION: 300,
	TEXTILE_ENGINEERING: 301,
	THEATRE: 302,
	THEOLOGY: 303,
	TOURISM: 304,
	TOXICOLOGY: 305,
	TURFGRASS_SCIENCE: 306,
	URBAN_PLANNING: 307,
	URBAN_STUDIES: 308,
	VISUAL_COMMUNICATION: 309,
	VOICE: 310,
	WEB_DESIGN: 311,
	WEBMASTER_AND_WEB_MANAGEMENT: 312,
	WELDING_ENGINEERING: 313,
	WILDLIFE_MANAGEMENT: 314,
	WOMENS_STUDIES: 315,
	YOUTH_MINISTRIES: 316,
	ZOOLOGY: 317,
} as const;
type Major = typeof MAJORS[keyof typeof MAJORS];

export {
	MAJORS,
	type Major,
};
