import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { LAYOUTS, type Layout } from '@/composables';
import type { SingleGallery } from '@/types/galleries';

export const useGalleryStore = defineStore('gallery', () => {
	const cachedGallery = ref<SingleGallery>();
	const galleryLayout = ref<Layout>(LAYOUTS.VIRTUAL_TILE);

	const setCachedGallery = (gallery: SingleGallery) => {
		cachedGallery.value = gallery;
	};

	const getCachedGallery = (galleryId: string) => {
		return computed<SingleGallery | undefined>(() => {
			if (cachedGallery.value?.uuid !== galleryId) return undefined;
			return cachedGallery.value;
		});
	};

	const $reset = () => {
		cachedGallery.value = undefined;
		galleryLayout.value = LAYOUTS.VIRTUAL_TILE;
	};

	return {
		galleryLayout,
		cachedGallery,
		getCachedGallery,
		setCachedGallery,
		$reset,
	};
});
