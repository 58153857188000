import { getActivePinia, type Pinia, type Store } from 'pinia';

interface ExtendedPinia extends Pinia {
	_s: Map<string, Store>;
}
export const $ResetPinia = (): Record<string, () => void> => {
	const pinia = getActivePinia() as ExtendedPinia;

	if (!pinia) {
		throw new Error('There are no stores');
	}

	const resetStores: Record<string, () => void> = {};

	pinia._s.forEach((store, name) => {
		resetStores[name] = () => store.$reset();
	});

	resetStores.all = () => pinia._s.forEach((store) => store.$reset());
	resetStores.profile = () => {
		const ignoredStores = ['user', 'auth', 'all'];
		pinia._s.forEach((store, name) => {
			if (!ignoredStores.includes(name)) store.$reset();
		});
	};
	return resetStores;
};
