import {
	EXCHANGE_BRAND_ENTITY_TYPES,
	EXCHANGE_BRAND_USER_TYPES,
	type ApiExchangeBrandProfile,
	type ExchangeBrandEntityType,
	type ExchangeBrandUserType,
} from './exchangeBrands';
import type { ApiMediaAttachment } from './media';
import type { School } from './schools';

const ROLES = {
	SUPER_ADMINISTRATOR: 1,
	ADMINISTRATOR: 2,
	CLIENT_SUCCESS: 3,
	TEAM_USER: 4,
	COMPLIANCE_OFFICER: 5,
	AGENT: 6,
	BRAND_USER: 7,
} as const;

type Role = typeof ROLES[keyof typeof ROLES];

const PERMISSIONS = {
	TEAM_ADMIN: 2,
	TEAM_CONTENT_HUB_READ: 3,
	TEAM_CONTENT_HUB_WRITE: 4,
	TEAM_DASHBOARD_READ: 5,
	TEAM_SOCIAL_CHANNELS_READ: 6,
	TEAM_SOCIAL_CHANNELS_WRITE: 7,
	TEAM_SOCIAL_FEED_READ: 8,
	TEAM_NOTIFICATIONS_READ: 9,
	TEAM_NOTIFICATIONS_WRITE: 10,
	ATHLETE_PROFILE_READ: 11,
	ATHLETE_PROFILE_WRITE: 12,
	TEAM_CONTENT_HUB_MEDIA_TAGGED_ONLY_READ: 13,
	TEAM_MESSAGING_READ: 14,
	TEAM_CAMPAIGN_READ: 15,
	TEAM_CAMPAIGN_WRITE: 16,
	MEDIA_REQUEST_READ: 17,
	MEDIA_REQUEST_WRITE: 18,
	TEAM_PROVIDER_READ: 19,
	OPPORTUNITIES_READ: 20,
	OPPORTUNITIES_WRITE: 21,
	TRANSACTIONS_READ: 22,
	TRANSACTIONS_WRITE: 23,
	FMV_VIEW: 24,
	LEADERBOARD_READ: 25,
	LEADERBOARD_WRITE: 26,
	SERVICE_PROVIDER_READ: 27,
	TRANSACTIONS_IMPORT_WRITE: 28,
} as const;

type Permission = typeof PERMISSIONS[keyof typeof PERMISSIONS];

interface BaseUser {
	uuid: Uuid;
	first_name: string;
	last_name: string;
}

interface Athlete extends BaseUser {
	image: string | null;
}

interface Team {
	uuid: string;
	name: string;
	banner: string | null;
	logo: string | null;
	services: TeamServices;
}

interface TeamServices {
	brand_strategy: boolean;
	data_services: boolean;
}

interface ProfilePermission {
	id: Permission;
	name: string;
}

interface Group {
	uuid: string;
	name: string;
	permissions: ProfilePermission[];
}

interface BaseProfile {
	uuid: string;
	global_exchange_opt_in: boolean;
}

interface AxleProfile {
	user_id: string | null;
	org_id: string | null;
}

interface SuperAdministratorProfile extends BaseProfile {
	role_id: typeof ROLES.SUPER_ADMINISTRATOR;
}

interface AdministratorProfile extends BaseProfile {
	role_id: typeof ROLES.ADMINISTRATOR;
}

interface ClientSuccessProfile extends BaseProfile {
	role_id: typeof ROLES.CLIENT_SUCCESS;
}

interface BrandUserProfile extends BaseProfile {
	role_id: typeof ROLES.BRAND_USER;
	exchange_brand_user_type: ExchangeBrandUserType;
	exchange_brand: ApiExchangeBrandProfile;
}

interface TeamUserProfile extends BaseProfile {
	role_id: typeof ROLES.TEAM_USER;
	academic_profile: {
		school: School;
		team: Team;
		group: Group;
		jersey_number: string | null;
		position: string | null;
		local_exchange_opt_in: boolean;
	};
	axle: AxleProfile;
}

interface ComplianceOfficerProfile extends BaseProfile {
	role_id: typeof ROLES.COMPLIANCE_OFFICER;
	school: School;
}

interface AgentProfile extends BaseProfile {
	role_id: typeof ROLES.AGENT;
	athlete: Athlete;
}

type UserProfile =
	| SuperAdministratorProfile
	| AdministratorProfile
	| ClientSuccessProfile
	| BrandUserProfile
	| TeamUserProfile
	| ComplianceOfficerProfile
	| AgentProfile;

function isBrandUserProfile(profile: UserProfile | undefined): profile is BrandUserProfile {
	return !!profile && profile.role_id === ROLES.BRAND_USER;
}

interface UserTermsOfUse {
	latest: string;
	accepted: string;
}

interface UserPaymentProcessingOnboardingStatus {
	stripe: boolean;
	wallet: boolean;
}

interface User extends BaseUser {
	email: string;
	phone: string;
	intercom_identity: string;
	terms_of_use: UserTermsOfUse;
	image: string | null;
	user_profiles: UserProfile[];
	teamworks_assigned: boolean;
	external_profile_url: string;
	has_okta_accounts: boolean;
	onboarding_status: UserPaymentProcessingOnboardingStatus;
}

interface UserResponse {
	data: User;
}

const APP_SWITCHER_SLUGS = {
	AMS: 'ams',
	ARMS: 'arms',
	ACADEMICS: 'academics',
	CAMPS: 'camps',
	COLLECTIVES: 'collectives',
	COMMUNITIES: 'communities',
	HUB: 'hub',
	INFLCR: 'inflcr',
	INVENTORY: 'inventory',
	NUTRITION: 'nutrition',
	PATHWAYS: 'pathways',
	PULSE: 'pulse',
	STRENGTH: 'strength',
	NOTEMEAL: 'notemeal',
	RETAIN: 'retain',
	SMARTABASE: 'smartabase',
	WHISTLE: 'whistle',
} as const;

type AppSwitcherSlug = typeof APP_SWITCHER_SLUGS[keyof typeof APP_SWITCHER_SLUGS];

type AppSwitcherUrlProperties = {
	[K in AppSwitcherSlug as `${K}_target_url`]?: string | null
};

type AppSwitcherLoginAccessProperties = {
	login_access: {
		web: {
			[K in AppSwitcherSlug as `${K}`]?: boolean
		};
		mobile: {
			[K in AppSwitcherSlug as `${K}`]?: boolean
		};
	};
};

interface AppSwitcherUserProfile extends AppSwitcherUrlProperties, AppSwitcherLoginAccessProperties {
	is_collegiate: boolean | null;
	name: string | null;
	navbar_bottom_color: string | null;
	navbar_image_url: string | null;
	navbar_top_color: string | null;
	profile_uuid: string | null;
}

export interface UserConfirmationCodeResponse {
	email: string;
	first_name?: string;
	last_name?: string;
	phone?: string;
}

interface ConfirmUserRequest {
	password: string;
	password_confirmation: string;
	first_name: string;
	last_name: string;
	phone?: string;
}

type UpdateUserRequest = Partial<Pick<User,
	| 'first_name'
	| 'last_name'
	| 'email'
	| 'phone'
> & { image: ApiMediaAttachment }>;

class ProfileAccessService {
	private userProfile?: UserProfile | null;
	private userProfilePermissions: Permission[] = [];
	private userProfileExchangeEntityType?: ExchangeBrandEntityType | null;
	private userProfileExchangeUserType?: ExchangeBrandUserType | null;
	hasContentHubTaggedOnlyRead = false;
	hasContentHubRead = false;
	hasContentHubWrite = false;
	hasCollaborationHubRead = false;
	hasTeamManagementRead = false;
	hasLeaderboardRead = false;
	hasLeaderboardWrite = false;
	hasExchangeWrite = false;
	hasAgentWrite = false;
	hasComplianceLedgerWrite = false;
	canImpersonate = false;
	canAccessBulletins = false;
	canCreateConversations = false;
	canViewAthleteContactInfo = false;
	hasAppSwitcher = false;
	isExchangeBrandAdmin = false;
	isServiceProvider = false;
	hasServiceProviderRead = false;
	hasTransactionsImportWrite = false;

	constructor(profile?: UserProfile | null) {
		this.userProfile = profile;
		this.userProfilePermissions = profile?.role_id === ROLES.TEAM_USER
			? profile.academic_profile.group.permissions.map((p) => p.id)
			: [];
		this.userProfileExchangeEntityType = profile?.role_id === ROLES.BRAND_USER ? profile.exchange_brand.entity_type : null;
		this.userProfileExchangeUserType = profile?.role_id === ROLES.BRAND_USER ? profile.exchange_brand_user_type : null;

		this.hasContentHubTaggedOnlyRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [
				PERMISSIONS.TEAM_ADMIN,
				PERMISSIONS.TEAM_CONTENT_HUB_WRITE,
				PERMISSIONS.TEAM_CONTENT_HUB_READ,
				PERMISSIONS.TEAM_CONTENT_HUB_MEDIA_TAGGED_ONLY_READ,
			],
		});

		this.hasContentHubRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [
				PERMISSIONS.TEAM_ADMIN,
				PERMISSIONS.TEAM_CONTENT_HUB_WRITE,
				PERMISSIONS.TEAM_CONTENT_HUB_READ,
			],
		});

		this.hasContentHubWrite = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [
				PERMISSIONS.TEAM_ADMIN,
				PERMISSIONS.TEAM_CONTENT_HUB_WRITE,
			],
		});

		this.hasCollaborationHubRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [
				PERMISSIONS.TEAM_ADMIN,
				PERMISSIONS.TEAM_CAMPAIGN_READ,
				PERMISSIONS.TEAM_CAMPAIGN_WRITE,
				PERMISSIONS.MEDIA_REQUEST_READ,
				PERMISSIONS.MEDIA_REQUEST_WRITE,
			],
		});

		this.hasTeamManagementRead = this.isPermitted({
			roles: [
				ROLES.TEAM_USER,
			],
			permissions: [PERMISSIONS.TEAM_ADMIN],
		});

		this.hasLeaderboardRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [
				PERMISSIONS.LEADERBOARD_READ,
				PERMISSIONS.LEADERBOARD_WRITE,
			],
		});

		this.hasLeaderboardWrite = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [PERMISSIONS.LEADERBOARD_WRITE],
		});

		this.hasExchangeWrite = this.isPermitted({
			roles: [ROLES.BRAND_USER],
		});

		this.hasAgentWrite = this.isPermitted({
			roles: [ROLES.AGENT],
		});

		this.hasComplianceLedgerWrite = this.isPermitted({
			roles: [ROLES.COMPLIANCE_OFFICER],
		});

		this.canImpersonate = this.isPermitted({
			roles: [
				ROLES.SUPER_ADMINISTRATOR,
				ROLES.ADMINISTRATOR,
				ROLES.CLIENT_SUCCESS,
			],
		});

		this.canAccessBulletins = this.isPermitted({
			roles: [ROLES.BRAND_USER],
			exchangeEntityType: EXCHANGE_BRAND_ENTITY_TYPES.BUSINESS,
		});

		this.canCreateConversations = this.isPermitted({
			roles: [ROLES.BRAND_USER],
			exchangeEntityType: EXCHANGE_BRAND_ENTITY_TYPES.BUSINESS,
		});

		this.canViewAthleteContactInfo = this.isPermitted({
			roles: [ROLES.BRAND_USER],
			exchangeEntityType: EXCHANGE_BRAND_ENTITY_TYPES.BUSINESS,
		});

		this.hasAppSwitcher = this.isPermitted({
			roles: [ROLES.TEAM_USER],
		});

		this.isExchangeBrandAdmin = this.isPermitted({
			roles: [ROLES.BRAND_USER],
			exchangeUserType: EXCHANGE_BRAND_USER_TYPES.EXCHANGE_ADMIN,
		});

		this.isServiceProvider = this.isPermitted({
			roles: [ROLES.BRAND_USER, ROLES.AGENT],
		});

		this.hasServiceProviderRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [PERMISSIONS.SERVICE_PROVIDER_READ],
		});

		this.hasTransactionsImportWrite = this.isPermitted({
			roles: [ROLES.COMPLIANCE_OFFICER],
		}) || this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [PERMISSIONS.TRANSACTIONS_IMPORT_WRITE],
		});
	}

	private hasRole(...roles: Role[]): boolean {
		if (!this.userProfile) return false;
		return roles.includes(this.userProfile.role_id);
	}

	private hasPermission(...permissions: Permission[]): boolean {
		if (!this.userProfile) return false;
		if (!this.hasRole(ROLES.TEAM_USER)) return true;
		return permissions.some((p) => this.userProfilePermissions.includes(p));
	}

	private hasExchangeEntityType(exchangeEntityType: ExchangeBrandEntityType): boolean {
		if (!this.userProfile) return false;
		if (!this.hasRole(ROLES.BRAND_USER)) return true;
		return this.userProfileExchangeEntityType === exchangeEntityType;
	}

	private hasExchangeUserType(exchangeUserType: ExchangeBrandUserType): boolean {
		if (!this.userProfile) return false;
		if (!this.hasRole(ROLES.BRAND_USER)) return false;
		return this.userProfileExchangeUserType === exchangeUserType;
	}

	isPermitted(requiredAccess: { roles?: Role[]; permissions?: Permission[]; exchangeEntityType?: ExchangeBrandEntityType; exchangeUserType?: ExchangeBrandUserType }) {
		const { roles = [], permissions = [], exchangeEntityType, exchangeUserType } = requiredAccess;
		if (roles.length > 0 && !this.hasRole(...roles)) return false;
		if (permissions.length && !this.hasPermission(...permissions)) return false;
		if (exchangeEntityType !== undefined && !this.hasExchangeEntityType(exchangeEntityType)) return false;
		if (exchangeUserType !== undefined && !this.hasExchangeUserType(exchangeUserType)) return false;
		return true;
	}
}

export {
	ROLES,
	PERMISSIONS,
	APP_SWITCHER_SLUGS,
	type Role,
	type Permission,
	type BaseUser,
	type Athlete,
	type Team,
	type TeamServices,
	type ProfilePermission,
	type Group,
	type BaseProfile,
	type AxleProfile,
	type SuperAdministratorProfile,
	type AdministratorProfile,
	type ClientSuccessProfile,
	type BrandUserProfile,
	type TeamUserProfile,
	type ComplianceOfficerProfile,
	type AgentProfile,
	type UserProfile,
	type UserTermsOfUse,
	isBrandUserProfile,
	type UserPaymentProcessingOnboardingStatus,
	type User,
	type UserResponse,
	type AppSwitcherUrlProperties,
	type AppSwitcherLoginAccessProperties,
	type AppSwitcherUserProfile,
	type UpdateUserRequest,
	type AppSwitcherSlug,
	type ConfirmUserRequest,
	ProfileAccessService,
};
